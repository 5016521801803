import React from 'react'
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box, Button, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import shortid from 'shortid'
import { useLocalStorage } from "../UseLocalStorage";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ReplayIcon from '@mui/icons-material/Replay';
import Tooltip from '@mui/material/Tooltip';
import { Brightness6Rounded } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
//Stepper
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Check from '@mui/icons-material/Check';
//formulario y validador yup
import * as yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import { cargarSolicitudDeAlta, setearRobot, obtenerCondicionesDeInscripcionFETCH } from '../Redux/DatosSolicitudAlta'
import { MuiFileInput } from 'mui-file-input';
import CloseIcon from '@mui/icons-material/Close';
//iconos
//1
import AssignmentIndSharpIcon from '@mui/icons-material/AssignmentIndSharp';
//2
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
//3
import RequestQuoteSharpIcon from '@mui/icons-material/RequestQuoteSharp';
//4
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
//Formularios
import SolicitudAltaGeneral from '../Onboarding/SolicitudAltaGeneral';
import SolicitudAltaPersonal from '../Onboarding/SolicitudAltaPersonal';
import SolicitudAltaCuenta from '../Onboarding/SolicitudAltaCuenta';
import SolicitudAltaAdicional from '../Onboarding/SolicitudAltaAdicional';
import SolicitudAltaFinal from '../Onboarding/SolicitudAltaFinal';

import { agregarDocumentoAcarpeta, obtenerDocumentosOnboardingFETCH } from '../Redux/DatosSolicitudAlta';
import { useDispatch, useSelector } from 'react-redux'
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DescriptionIcon from '@mui/icons-material/Description';
import { loginToken } from '../Redux/Token'
import FormHelperText from '@mui/material/FormHelperText';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

const SolicitudAlta = (props) => {
    const dispatch = useDispatch()
    const [activeStep, setActiveStep] = React.useState(1);
    const [documentos, setDocumentos] = React.useState([])
    const [datosDocumento, setDatosDocumento] = React.useState("")
    const [nombreDocumento, setNombreDocumento] = React.useState("")
    const [documentoId, setDocumentoId] = React.useState("")
    const [documentosIds, setDocumentosIds] = React.useState("")
    const [docusCargados, setDocusCargados] = React.useState(0)
    const [documentosDTOs, setDocumentosDTOs] = React.useState([])
    const [selectedFiles, setSelectedFiles] = React.useState([])
    const [pesoArch, setPesoArch] = React.useState(0)
    const [carpeta, setCarpeta] = React.useState([])
    const [carpetas, setCarpetas] = React.useState([])
    const [llamadaDocumentos, setLlamadaDocumentos] = React.useState(false)
    const [llamadaCondiciones, setLlamadaCondiciones] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [archivoSeleccionado, setArchivoSeleccionado] = React.useState('')
    const [loaded, setLoaded] = React.useState(false)
    const documentosSelector = useSelector(store => store.datos.documentos)
    const carpetasSelector = useSelector(store => store.datos.carpetas)
    const estadoOnboardingSelector = useSelector(store => store.datos.estadoOnboarding)
    const robot = useSelector(store => store.datos.robot)
    const condicionesSelector = useSelector(store => store.datos.condicionesAFIP)
    const [OnboardingCompleto, setOnboardingCompleto] = React.useState(false)
    const [mostrarIconoFinal, setMostrarIconoFinal] = React.useState(false)
    const [mostrarTextoFinal, setMostrarTextoFinal] = React.useState(false)
    const [desabilitadoDocumento, setDesabilitadoDocumento] = React.useState(false)
    const [dense, setDense] = React.useState(false);
    const token = useSelector(store => store.token.token)
    const [secondary, setSecondary] = React.useState(false);
    const [loadingAccionistas, setLoadingAccionistas] = React.useState()
    const [condicionesAFIP, setCondicionesAFIP] = React.useState()
    const [documentosFiltradosPorPersoneria, setDocumentosFiltradosPorPersoneria] = React.useState([])
    //estados de los modales
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [openCarpeta, setOpenCarpeta] = React.useState(false);
    const [openReiniciarOnboarding, setOpenReiniciarOnboarding] = React.useState(false);
    const [openEliminarArchivo, setOpenEliminarArchivo] = React.useState(false);
    const [openEliminarAccionista, setOpenEliminarAccionista] = React.useState(false);
    //documentos
    const handleOpen = () => {
        setOpen(true)
        setDesabilitadoDocumento(false)
    }
    const handleClose = () => setOpen(false);
    //accionista
    const handleOpen2 = () => { setOpen2(true) }
    const handleClose2 = () => {
        setOpen2(false)
        setRazonSoc('')
        setPerson('')
        setNombre('')
        setApellido('')
        setcuitCUIL('')
        setPercentaje(null)
        setPorcentajeAux(null)
        setEsActualizacion(false)
    }
    const handleOpenCarpeta = () => { setOpenCarpeta(true) }
    const handleCloseCarpeta = () => { setOpenCarpeta(false) }
    const handleCloseEliminarArchivo = () => setOpenEliminarArchivo(false)

    const handleOpenEliminarAccionista = (id) => {
        setOpenEliminarAccionista(true)
        setAccionistaID(id)
    }
    const handleCloseReiniciarOnboarding = () => {
        setOpenReiniciarOnboarding(false)
    }
    const handleCloseEliminarAccionista = () => setOpenEliminarAccionista(false)

    const [archivos, setArchivos] = React.useState([]) //archivos que enviaremos

    const defaultValues = {
        servicio: '100000000',
        personeria: '',
        tipoDeDocumento: null,
        nombreRazonSocial: '',
        cuitCuilCdi: '',
        nombre: '',
        apellido: '',
        cuitCuil: '',
        telefono: '',
        usuarioPortal: '',
        emailNotificaciones: '',
        relacionCuenta: '',
        actividad: null,
        tipoSocietario: '',
        condicionImp: null,
        facturacionIngreso: null,
        cuitReferidor: null,
        cantidadMujeresDecision: '',
        cantidadEmujeres: '',
        cantidadPdiscapacidad: '',
        step: 0
    }

    const docsValues = {
        docsDTOs: [],
        documentosIds: '',
        documentosDTOs: [],
        docusCargados: [],
        archivos: [],
        accionistas: []
    }

    const [storage, setStorage] = useLocalStorage("formValues", defaultValues);
    const [storageDocs, setStorageDocs] = useLocalStorage("docsValues", docsValues);
    const [accionistaID, setAccionistaID] = React.useState('')
    const [accionistas, setAccionistas] = React.useState([])
    const [person, setPerson] = React.useState('')
    const [razonSoc, setRazonSoc] = React.useState('')
    const [nombre, setNombre] = React.useState('')
    const [apellido, setApellido] = React.useState('')
    const [cuitCUIL, setcuitCUIL] = React.useState('')
    const [percentaje, setPercentaje] = React.useState(null)
    const [porcentajeAux, setPorcentajeAux] = React.useState('')
    const [habilitarEdicion, setHabilitarEdicion] = React.useState(false)
    const [esActualizacion, setEsActualizacion] = React.useState(false)
    const [desabilitadoEnvio, setDeshabilitadoEnvio] = React.useState(false)

    const obtenerTodosDocumentos = () => {
        dispatch(obtenerDocumentosOnboardingFETCH(token.token))
    }

    const eliminarAccionista = (uid) => {
        var accionistasAux = accionistas.filter(item => item.uid === uid)
        if (accionistasAux.length > 0) {
            var i = accionistas.indexOf(accionistasAux[0])
            if (i !== -1) {
                accionistas.splice(i, 1);
            }
        }
        var currentData = localStorage.getItem("docsValues")
        currentData = currentData ? JSON.parse(currentData) : {};
        currentData.accionistas = accionistas
        localStorage.setItem("docsValues", JSON.stringify(currentData))
        setOpenEliminarAccionista(false)
    }

    const ValidateCUITCUIL = (cuit) => {
        let acumulado = 0;
        let respuesta = false;
        let digitos = cuit.split('');
        let digito = parseInt(digitos.pop());

        for (let i = 0; i < digitos.length; i++) {
            acumulado += digitos[9 - i] * (2 + (i % 6));
        }

        let verif = 11 - (acumulado % 11);
        if (verif === 11) {
            verif = 0;
        } else if (verif === 10) {
            verif = 9;
        }
        respuesta = (digito === verif);
        return respuesta;
    }

    var validarDocumentosCargador = () => {
        var requeridosCargados = true
        var documentosFiltrados = []

        documentosFiltrados = filtrarDocumentosPorCondicionAFIP(condicionesAFIP, documentosDTOs, condicionImpositiva)

        documentosFiltrados.forEach(doc => {
            if (doc.new_requeridoenportal != null && doc.new_requeridoenportal == true) {
                var documentosCargados = documentosIds.split(',')
                if (documentosCargados.filter(item => item.trim() == doc.new_documentacionid).length == 0) {
                    requeridosCargados = false
                }
            }
        })
        return requeridosCargados
    }

    const validarArchivosCargadosConFiltrados = (documentosFiltradosAFIP) => {
        for (let index = 0; index < archivos.length; index++) {
            let documentoID = archivos[index].documentoId
            let nombreDocumento = archivos[index].name
            if (documentosFiltradosAFIP.length > 0 &&
                documentosFiltradosAFIP.filter(item => item.new_documentacionid == documentoID).length == 0) {
                eliminarArchivoFiltrado(nombreDocumento, documentoID)
            }
        }
        setDocumentosDTOs(documentosFiltradosAFIP)
    }

    const filtrarDocumentosPorCondicionAFIP = (condiciones, docs, condicionImpositiva) => {
        var documentosFiltrados = []
        var documentosFiltradosXAFIP = []

        if (condiciones != undefined) {
            condiciones.filter(item => item.new_condiciondeinscipcionanteafipid == condicionImpositiva.value).forEach(condicion => {
                if (documentosFiltrados.length > 0 && condicion.new_documentacionid != null && condicion.new_documentacionid != "") {
                    if (documentosFiltrados.filter(item => item == condicion.new_documentacionid).length == 0) {
                        documentosFiltrados.push(condicion.new_documentacionid)
                    }
                } else {
                    if (condicion.new_documentacionid != null && condicion.new_documentacionid != "") {
                        documentosFiltrados.push(condicion.new_documentacionid)
                    }
                }
            })
        }

        if (docs != undefined && docs.length > 0) {
            docs.forEach(documento => {
                if (documentosFiltrados.length > 0 &&
                    documentosFiltrados.filter(item => item == documento.new_documentacionid).length > 0) {
                    documentosFiltradosXAFIP.push(documento)
                } else if (condiciones != undefined && condiciones.length > 0) {
                    if (condiciones.filter(item => item.new_documentacionid == documento.new_documentacionid).length == 0) {
                        documentosFiltradosXAFIP.push(documento)
                    }
                } else {
                    documentosFiltradosXAFIP.push(documento)
                }
            })
        }

        return documentosFiltradosXAFIP
    }

    const validarPorcentajeAccionistas = () => {
        var porcentajeValido = true
        var porcentajeTotal = 0
        accionistas.forEach(accionista => {
            if (accionista.porcentaje != null && accionista.porcentaje != 0) {
                porcentajeTotal += parseFloat(accionista.porcentaje)
            }
        })
        if (porcentajeTotal != 100) {
            porcentajeValido = false
        }
        return porcentajeValido
    }

    const validacionSchema = [
        //NOTA: si no hay validaciones en algun paso, debe pasarse vacio el yup.object({}) para que funcione el nextStep.
        //validacion paso 1
        yup.object({
            servicio: yup.string().required(),
            personeria: yup.string().required(),
            tipoDeDocumento: yup.object().required(),
            nombreRazonSocial: yup.string().required("Nombre o Razón Social es requerido"),
            cuitCuilCdi: yup.number('Sin espacios ni guiones').required().positive().integer()
        }),
        //validacion paso 2
        yup.object({
            nombre: yup.string().required(),
            apellido: yup.string().required(),
            cuitCuil: yup.number('Sin espacios ni guiones').required().positive().integer(),
            telefono: yup.number().required().positive().integer(),
            usuarioPortal: yup.string().email().required(),
            emailNotificaciones: yup.string().email().required(),
            relacionCuenta: yup.string().required()
        }),
        //validacion paso 3
        yup.object({
            actividad: yup.object().required(),
            tipoSocietario: yup.string().when('personeria', (personeria) => {
                return personeria == '100000000' ? yup.string().required() : yup.string().notRequired()
            }),
            condicionImp: yup.object().required(),
            // condicionImp: yup.object().when('personeria', (personeria) => {
            //     return personeria == '100000001' ? yup.object({
            //         value: yup.string().required()
            //     }) : yup.object().nullable(true)
            // }),
            facturacionIngreso: yup.string().required(),
        }),
        //validacion paso 4
        yup.object({
            cantidadMujeresDecision: yup.string().required(),
        }),
    ];

    //valida segun el step en el que estoy parado
    const validacionActualSchema = validacionSchema[activeStep]

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(validacionActualSchema),
        mode: "onChange"
    })

    const { handleSubmit, trigger, setValue } = methods;

    const formData = methods.watch();

    const resetOnb = () => {
        methods.reset()
    }

    //############## ESCUCHA en TIEMPO REAL los DATOS COMPLETADOS en el FORMULARIO ##############

    //esta escucha en tiempo real la utilizo para saber que selecciona la persona que hace el onboarding
    //y depende de lo que seleccione, yo cambio valores de selects o inputs
    //esto lo hago debido a que el objeto "defaultValues" no se completa en tiempo real 
    //pero de esta forma si podemos saber que se esta completando

    const servicio = methods.watch('servicio')
    const personeria = methods.watch('personeria')
    const condicionImpositiva = methods.watch('condicionImp')
    const relacionCuentaForm = methods.watch('relacionCuenta')
    const tipoDeDocumento = methods.watch('tipoDeDocumento')
    const documentosDtos = methods.watch('documentosDTOs')
    const nombreForm = methods.watch('nombre')
    const apellidoForm = methods.watch('apellido')
    const cuitCuilForm = methods.watch('cuitCuil')
    const cuitCuilCdi = methods.watch('cuitCuilCdi')
    const actividad = methods.watch('actividad')
    const tipoSocietario = methods.watch('tipoSocietario')
    const referidor = methods.watch('cuitReferidor')
    //############### PASOS DEL MULTISTEP ###############

    const handleNext = async () => {
        //el trigger valida que se complete el campo requerido
        const esPasoValido = await trigger();
        if (activeStep == 0) {
            if (esPasoValido) {
                var cuitValido = true
                cuitValido = ValidateCUITCUIL(cuitCuilCdi)
                if (!cuitValido) {
                    toast.error('El CUIT/CUIL debe ser válido!', {
                        theme: "dark",
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    return
                }
            }
        }
        if (activeStep == 1) {
            if (esPasoValido) {
                var cuitValido = true
                cuitValido = ValidateCUITCUIL(cuitCuilForm)
                if (!cuitValido) {
                    toast.error('El CUIT/CUIL debe ser válido!', {
                        theme: "dark",
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    return
                }
            }
            if (accionistas.length > 0 && accionistas.filter(item => item.relacionDirecta == true).length > 0) {
                var accionistasDirectos = accionistas.filter(item => item.relacionDirecta == true)
                if (accionistasDirectos != undefined && accionistasDirectos.length > 0) {
                    actualizarAccionistaDirecto(accionistasDirectos[0].uid)
                }
            }
        }
        if (activeStep == 2) {
            setDocumentosDTOs(documentosFiltradosPorPersoneria)
        }
        if (activeStep == 4) {
            if (esPasoValido) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else {
            if (esPasoValido) setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //############### BOTON SUBMIT ###############
    const submitDatos = (datos) => {
        setDeshabilitadoEnvio(true)
        let documentacionCargada = true
        let porcentajeValido = true

        documentacionCargada = validarDocumentosCargador()
        if (!documentacionCargada) {
            toast.error('Por favor, adjunte la documentación requerida', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setDeshabilitadoEnvio(false)
            return
        }
        if (personeria == "100000000") { //Juridica
            porcentajeValido = validarPorcentajeAccionistas()
            if (!porcentajeValido) {
                toast.error('El porcentaje de tenencia accionaria debe conformar el 100%', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setDeshabilitadoEnvio(false)
                return
            }
        }

        if (robot == false) {
            toast.error('Debes confirmar que no eres un robot', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setDeshabilitadoEnvio(false)
            return
        }

        var datosFinales = {
            ...datos, //copia del defaultvalues + los documentos y demas que deberia mandar a la api
            ["documentosDTOs"]: documentosDTOs,
            ["documentos"]: documentos,
            ["documentosIds"]: documentosIds,
            ["documentosCargados"]: docusCargados,
            ["pesoArchivos"]: pesoArch,
            ["accionistas"]: accionistas,
            ["condicionesAFIP"]: condicionesAFIP
        }

        dispatch(cargarSolicitudDeAlta(datosFinales, archivos, token.token))
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    //##### los label de steps #####
    const steps = ['Datos de la Cuenta', 'Datos del Contacto', 'Datos Adicionales', 'Documentación', 'Confirmación'];
    //podemos cambiarlos o dejarlos vacios

    //##### formularios que muestra segun el step #####
    const mostrarFormularios = (step) => {
        switch (step) {
            case 0:
                // return <SolicitudAltaFinal OnboardingCompleto={OnboardingCompleto} />;
                return <SolicitudAltaGeneral personeria={personeria} token={token} />;
            case 1:
                return <SolicitudAltaPersonal personeria={personeria} />;
            case 2:
                return <SolicitudAltaCuenta personeria={personeria} token={token} />;
            case 3:
                return <SolicitudAltaAdicional
                    personeria={personeria}
                    condicionImpositiva={condicionImpositiva}
                    documentosFiltrados={documentosDTOs}
                    condicionesAFIP={condicionesAFIP}
                    docsDTOs={documentosDtos}
                    handleOpen={handleOpen}
                    setDatosDocumento={setDatosDocumento}
                    handleOpen2={handleOpen2}
                    handleOpenCarpeta={handleOpenCarpeta}
                    accionistas={accionistas}
                    editarAccionista={editarAccionista}
                    handleOpenEliminarAccionista={handleOpenEliminarAccionista}
                    storage={storageDocs}
                    // agregarDocumentos={setDocumentosDTOs}
                    agregarDocumentos={validarArchivosCargadosConFiltrados}
                />;
            case 4:
                // return <SolicitudAltaGeneral personeria={personeria} />;
                return <SolicitudAltaFinal OnboardingCompleto={OnboardingCompleto} ResetOnboarding={resetOnb} />;
            default:
                throw new Error('Unknown step');
        }
    }

    //debi ubicar el useEffect en esta linea porque react da un error de scope 
    //al llamar a la funcion que genera el dispatch, no se si es por la ultima version de react

    React.useEffect(() => {
        if (token == undefined || token == "") {
            dispatch(loginToken())
        }
    }, [])

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            dispatch(loginToken());
        }, 900000);
        return () => clearInterval(intervalId);
    }, [])

    React.useEffect(() => {
        if (documentosSelector.length > 0 && llamadaDocumentos === true) {
            setDocumentos(documentosSelector)
        } else if (token !== "" && llamadaDocumentos === false) {
            obtenerTodosDocumentos()
            setLlamadaDocumentos(true)
        }
    }, [documentosSelector, token])

    React.useEffect(() => {
        if (condicionesSelector.length > 0 && llamadaCondiciones === true) {
            setCondicionesAFIP(condicionesSelector)
            var currentData = localStorage.getItem("docsValues")
            currentData = currentData ? JSON.parse(currentData) : {};
            currentData.condicionesAFIP = condicionesSelector
            localStorage.setItem("docsValues", JSON.stringify(currentData))
        } else if (token !== "" && llamadaCondiciones === false) {
            dispatch(obtenerCondicionesDeInscripcionFETCH(token.token))
            setLlamadaCondiciones(true)
        }
    }, [condicionesSelector, token])

    React.useEffect(() => {
        if (personeria !== '') {
            if (documentos.length > 0) {
                var filtrados = filtrarDocumentosPorPersoneria(documentos.filter(documento => documento.new_solicituddealta == true), personeria)
                setDocumentosDTOs(filtrados)
                setDocumentosFiltradosPorPersoneria(filtrados)
                var currentData = localStorage.getItem("docsValues")
                currentData = currentData ? JSON.parse(currentData) : {};
                currentData.docsDTOs = filtrados
                localStorage.setItem("docsValues", JSON.stringify(currentData))
            }
        }
    }, [personeria, documentos])

    React.useEffect(() => {
        if (archivos.length > 0 && activeStep == 0) {
            setArchivos([])
        }
        if (personeria == "100000001") { //Humana
            if (relacionCuentaForm == '100000001' || relacionCuentaForm == '100000002' ||
                relacionCuentaForm == '100000003' || relacionCuentaForm == '100000002') {
                setValue('relacionCuenta', '')
            }
            if (tipoSocietario != null && tipoSocietario != '') {
                setValue('tipoSocietario', '')
            }
        } else if (personeria == "100000000") { //Juridica
            if (relacionCuentaForm == '100000000') {
                setValue('relacionCuenta', '')
            }
        }
        if ((personeria == "100000001") && accionistas.length > 0) {
            setAccionistas([])
            var currentData = localStorage.getItem("docsValues")
            currentData = currentData ? JSON.parse(currentData) : {};
            currentData.accionistas = []
            localStorage.setItem("docsValues", JSON.stringify(currentData))
        }
    }, [personeria])

    React.useEffect(() => {
        if (carpetasSelector != undefined && carpetasSelector.length > 0) {
            setCarpetas(carpetasSelector)
        }
    }, [carpetasSelector])

    React.useEffect(() => {
        if (datosDocumento !== '') {
            var docu = datosDocumento.split(':')
            setNombreDocumento(docu[0])
            setDocumentoId(docu[1])
            setCarpeta(carpetas.filter(item => item.documentoid == docu[1]))
        }
    }, [datosDocumento])

    React.useEffect(() => {
        if (storageDocs.accionistas != undefined && storageDocs.accionistas.length > 0 && relacionCuentaForm === '100000001') {
            if (storageDocs.accionistas.filter(item => item.relacionDirecta == true).length > 0) {
                setAccionistas(storageDocs.accionistas)
            } else {
                agregarAccionista()
            }
        } else if (storageDocs.accionistas != undefined && storageDocs.accionistas.length > 0
            && relacionCuentaForm != "" && relacionCuentaForm !== '100000001') {
            var accionistasDirectos = storageDocs.accionistas
            if (accionistasDirectos != undefined && accionistasDirectos.length > 0) {
                accionistasDirectos.forEach(item => {
                    if (item.relacionDirecta == true) {
                        var indice = accionistasDirectos.indexOf(item)
                        accionistasDirectos.splice(indice, 1)
                    }
                })
                setAccionistas(accionistasDirectos)
                var currentData = localStorage.getItem("docsValues")
                currentData = currentData ? JSON.parse(currentData) : {};
                currentData.accionistas = accionistasDirectos
                localStorage.setItem("docsValues", JSON.stringify(currentData))
            }
        } else {
            if (relacionCuentaForm === '100000001') {
                if (accionistaID === '') { //Comprobar si ya se agrego el accionista para determinar si es creacion o actualizacion
                    agregarAccionista()
                } else if (accionistas.count > 0) {
                    actualizarAccionista(accionistaID)
                } else {
                    agregarAccionista()
                }
            } else {
                var accionistasDirectos = accionistas
                if (accionistasDirectos != undefined && accionistasDirectos.length > 0) {
                    accionistasDirectos.forEach(item => {
                        if (item.relacionDirecta == true) {
                            var indice = accionistasDirectos.indexOf(item)
                            accionistasDirectos.splice(indice, 1)
                        }
                    })
                    setAccionistas(accionistasDirectos)
                    var currentData = localStorage.getItem("docsValues")
                    currentData = currentData ? JSON.parse(currentData) : {};
                    currentData.accionistas = accionistasDirectos
                    localStorage.setItem("docsValues", JSON.stringify(currentData))
                }
            }
        }
    }, [relacionCuentaForm])

    React.useEffect(() => { //CARGA EL ONBOARDING CON EL LOCALSTORAGE
        if (!loaded) {
            methods.reset(storage);
            // let step = methods.getValues("step")
            // if (step === 3) {
            //     step = 2
            // }
            setActiveStep(0)
            setLoaded(true);
        }
    }, [storage, methods.reset, loaded]);

    React.useEffect(() => {
        if (activeStep == 4) {
            setMostrarIconoFinal(true)
            setTimeout(() => { setMostrarTextoFinal(true) }, 800)
        }
        methods.setValue("step", activeStep)
        var currentData = localStorage.getItem("formValues")
        currentData = currentData ? JSON.parse(currentData) : {};
        currentData.step = activeStep
        localStorage.setItem("formValues", JSON.stringify(currentData))
    }, [activeStep]);

    React.useEffect(() => {
        var currentData = localStorage.getItem("formValues")
        currentData = currentData ? JSON.parse(currentData) : {};
        currentData.servicio = servicio
        currentData.personeria = personeria
        currentData.condicionImp = condicionImpositiva
        currentData.tipoDeDocumento = tipoDeDocumento
        currentData.tipoSocietario = tipoSocietario
        currentData.actividad = actividad
        currentData.relacionCuenta = relacionCuentaForm
        currentData.cuitReferido = referidor
        localStorage.setItem("formValues", JSON.stringify(currentData))
    }, [servicio, personeria, condicionImpositiva, relacionCuentaForm, tipoDeDocumento, actividad, tipoSocietario, referidor]);

    React.useEffect(() => {
        if (estadoOnboardingSelector === "EXITO") {
            localStorage.clear();
            handleNext()
            dispatch(setearRobot(false))
            setDeshabilitadoEnvio(false)
        } else if (estadoOnboardingSelector === "ERROR") {
            setDeshabilitadoEnvio(false)
            dispatch(setearRobot(false))
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    }, [estadoOnboardingSelector]);


    const prepararModalEliminarArchivo = (archivo) => {
        setOpenEliminarArchivo(true)
        setArchivoSeleccionado(archivo)
    }

    const filtrarDocumentosPorPersoneria = (docs, personeria) => {
        var documentosFiltrados = []
        docs.forEach(doc => {
            if (doc.new_personeria != null) {
                if (doc.new_personeria == personeria) {
                    documentosFiltrados.push(doc)
                }
            } else {
                documentosFiltrados.push(doc)
            }
        })
        return documentosFiltrados
    }

    const changeHandler = (event) => {
        try {
            setSelectedFiles(event)
        } catch (error) {
            console.log(error)
        }
    };

    const filtrarDocumentos = (docs, condicionImpositiva) => {
        var documentosFiltrados = []
        docs.forEach(doc => {
            if (doc.new_condicionimpositiva != null) {
                var condiciones = doc.new_condicionimpositiva.split(',')
                condiciones.forEach(item => {
                    if (item === condicionImpositiva) {
                        documentosFiltrados.push(doc)
                    }
                })
            } else {
                documentosFiltrados.push(doc)
            }
        })
        return documentosFiltrados
    }

    const validarPorcentaje = (accionis, porcen) => {
        let porcentaje = 0.00
        if (accionis.length > 0) {
            accionis.forEach(element => {
                if (element.uid !== accionistaID) {
                    porcentaje = porcentaje + parseFloat(element.porcentaje)
                }
            });
            porcentaje = porcentaje + parseFloat(porcen)
            if (porcentaje > 100) {
                toast.error('El porcentaje total de los accionistas no puede ser mayor a 100%', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const agregarAccionista = () => {
        // let validarCuitAccionista = false;
        var existe = false
        if (accionistas.length > 0) {
            if (accionistas.filter(item => item.cuitcuil == cuitCuilForm).length > 0) {
                existe = true
            }
        }
        if (existe == false) {
            const accionistaArray = []
            const accionista = {
                uid: '',
                personeria: '',
                cuitcuil: '',
                razonSocial: '',
                nombre: '',
                apellido: '',
                porcentaje: 0,
                descripcion: '',
                tipoRelacion: '100000001',  //Accionista
                relacionDirecta: false
            }

            // let tipoRelacion = "100000001" //Accionista
            accionista.uid = shortid.generate()
            setAccionistaID(accionista.uid)
            accionista.personeria = "100000001" //FISICA
            accionista.cuitcuil = cuitCuilForm
            accionista.nombre = nombreForm
            accionista.apellido = apellidoForm
            // if (personeria == '100000000') { //Juridica
            //     accionista.razonSocial = nombreForm + " " + apellidoForm
            // } else if (person == '100000001') {
            //     accionista.nombre = nombreForm
            //     accionista.apellido = apellidoForm
            // }
            accionista.relacionDirecta = true
            accionistaArray.push(accionista)
            var todosAccionistas = [
                ...accionistas,
                { ...accionista }
            ]
            setAccionistas(todosAccionistas)
            var currentData = localStorage.getItem("docsValues")
            currentData = currentData ? JSON.parse(currentData) : {};
            currentData.accionistas = todosAccionistas
            localStorage.setItem("docsValues", JSON.stringify(currentData))
            setTimeout(() => {
                setRazonSoc('')
                setAccionistaID('')
                setNombre('')
                setApellido('')
                setcuitCUIL('')
                setPerson('')
                setPercentaje(null)
                setPorcentajeAux(null)
            }, 1500);
        }
    }

    //####Crea Accionista
    const crearAccionista = (e) => {
        e.preventDefault()
        if (person === '') {
            toast.error('La personeria es requerida!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        if (person === "100000000") { //Juridica
            if (razonSoc === '') {
                toast.error('La Razón Social es requerida!', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return
            }
        } else {
            if (nombre === '') {
                toast.error('El Nombre es requerido!', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return
            }
            if (apellido === '') {
                toast.error('El apellido es requerido!', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return
            }
        }
        if (cuitCUIL === '') {
            toast.error('El CUIT/CUIL es requerido!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        } else if (cuitCUIL.length > 11 || cuitCUIL.length < 11) {
            toast.error('El CUIT/CUIL es incorrecto, debe tener 11 caracteres!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        let validarCuitAccionista = ValidateCUITCUIL(cuitCUIL)
        if (!validarCuitAccionista) {
            toast.error('El CUIT/CUIL debe ser válido!', {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        if (percentaje === '') {
            toast.error('El porcentaje de participación es requerido!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        try {
            setLoadingAccionistas(true)
            let tipoRelacion = "100000001" //Accionista
            var accionista = {
                uid: '',
                personeria: '',
                cuitcuil: '',
                razonSocial: '',
                nombre: '',
                apellido: '',
                porcentaje: 0,
                descripcion: '',
                tipoRelacion: '',
                relacionDirecta: false
            }
            var validacion = validarPorcentaje(accionistas, percentaje)
            if (validacion) {
                accionista.uid = shortid.generate()
                accionista.cuitcuil = cuitCUIL
                accionista.personeria = person
                if (person === "100000000") {
                    accionista.razonSocial = razonSoc
                }
                else if (person === "100000001") {
                    accionista.nombre = nombre
                    accionista.apellido = apellido
                }
                accionista.porcentaje = percentaje
                // accionista.descripcion = descripcion
                accionista.tipoRelacion = tipoRelacion
                accionista.relacionDirecta = false
                var todosAccionistas = [
                    ...accionistas,
                    { ...accionista }
                ]
                setAccionistas(todosAccionistas)
                var currentData = localStorage.getItem("docsValues")
                currentData = currentData ? JSON.parse(currentData) : {};
                currentData.accionistas = todosAccionistas
                localStorage.setItem("docsValues", JSON.stringify(currentData))
                toast.success('Accionista cargado con exito', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    handleClose2()
                    setLoadingAccionistas(false)
                }, 2500);
            } else {
                setLoadingAccionistas(false)
            }
        } catch {
            setLoadingAccionistas(false)
        }
    }

    //####Llama al modal del accionista y le pasa los datos
    const editarAccionista = (id) => {
        handleOpen2()
        setAccionistaID(id)
        setEsActualizacion(true)
        accionistas.filter(accionista => accionista.uid == id).map(item => {
            setRazonSoc(item.razonSocial)
            setNombre(item.nombre)
            setApellido(item.apellido)
            setcuitCUIL(item.cuitcuil)
            // setPercentaje(item.porcentaje)
            setPorcentajeAux(item.porcentaje)
            // setDescripcion(item.descripcion)
            setPerson(item.personeria)
            setHabilitarEdicion(item.relacionDirecta)
        })
        setAccionistas(accionistas)
        var currentData = localStorage.getItem("docsValues")
        currentData = currentData ? JSON.parse(currentData) : {};
        currentData.accionistas = accionistas
        localStorage.setItem("docsValues", JSON.stringify(currentData))
    }

    const actualizarAccionista = (id) => {
        var razonSocial = ""
        var nombre = ""
        var apellido = ""
        if (personeria == "100000000") {
            razonSocial = nombreForm + " " + apellidoForm
        } else {
            nombre = nombreForm
            apellido = apellidoForm
        }
        const accionistaEditado = accionistas.map(item => item.uid === id ?
            {
                uid: id,
                personeria: personeria,
                cuitcuil: cuitCuilForm,
                razonSocial: razonSocial,
                nombre: nombre,
                apellido: apellido,
                porcentaje: Number(item.porcentaje) > 0 ? Number(item.porcentaje) : 0,
                // descripcion: item.descripcion,
                tipoRelacion: '100000001',
                relacionDirecta: true
            }
            : item)
        setAccionistas(accionistaEditado)
        var currentData = localStorage.getItem("docsValues")
        currentData = currentData ? JSON.parse(currentData) : {};
        currentData.accionistas = accionistaEditado
        localStorage.setItem("docsValues", JSON.stringify(currentData))
    }

    const actualizarAccionistaDirecto = (id) => {
        var razonSocial = ""
        var nombre = ""
        var apellido = ""
        // if (personeria == "100000000") {
        //     razonSocial = nombreForm + " " + apellidoForm
        // } else {
        //     nombre = nombreForm
        //     apellido = apellidoForm
        // }
        nombre = nombreForm
        apellido = apellidoForm
        const accionistaEditado = accionistas.map(item => item.uid === id ?
            {
                uid: id,
                personeria: "100000001",
                cuitcuil: cuitCuilForm,
                razonSocial: razonSocial,
                nombre: nombre,
                apellido: apellido,
                porcentaje: Number(item.porcentaje) > 0 ? Number(item.porcentaje) : 0,
                // descripcion: item.descripcion,
                tipoRelacion: '100000001',
                relacionDirecta: true
            }
            : item)
        setAccionistas(accionistaEditado)
        var currentData = localStorage.getItem("docsValues")
        currentData = currentData ? JSON.parse(currentData) : {};
        currentData.accionistas = accionistaEditado
        localStorage.setItem("docsValues", JSON.stringify(currentData))
    }

    //####Modifica al accionista y lo actualiza con los nuevos valores
    const modificarAccionista = (e) => {
        e.preventDefault()
        var porcentajeAccionista = percentaje != null ? percentaje : porcentajeAux
        if (person === '') {
            toast.error('La personeria es requerida!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        if (person === "100000000") { //Juridica
            if (razonSoc === '') {
                toast.error('La Razón Social es requerida!', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return
            }
        } else {
            if (nombre === '') {
                toast.error('El Nombre es requerido!', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return
            }
            if (apellido === '') {
                toast.error('El apellido es requerido!', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return
            }
        }
        if (cuitCUIL === '') {
            toast.error('El CUIT/CUIL es requerido!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        } else if (cuitCUIL.length > 11 || cuitCUIL.length < 11) {
            toast.error('El CUIT/CUIL es incorrecto, debe tener 11 caracteres!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        let validarCuitAccionista = ValidateCUITCUIL(cuitCUIL)
        if (!validarCuitAccionista) {
            toast.error('El CUIT/CUIL debe ser válido!', {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        if (percentaje === '') {
            toast.error('El porcentaje de participación es requerido!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        try {
            var validacion = validarPorcentaje(accionistas, porcentajeAccionista)
            if (validacion) {
                const accionista = {
                    uid: '',
                    personeria: '',
                    cuitcuil: '',
                    razonSocial: '',
                    nombre: '',
                    apellido: '',
                    porcentaje: 0,
                    descripcion: '',
                    tipoRelacion: '',
                    relacionDirecta: false
                }
                const accionistaEditado = accionistas.map(item => item.uid === accionistaID ?
                    {
                        uid: accionistaID,
                        personeria: person,
                        cuitcuil: cuitCUIL,
                        razonSocial: razonSoc,
                        nombre: nombre,
                        apellido: apellido,
                        porcentaje: porcentajeAccionista,
                        // descripcion: descripcion,
                        tipoRelacion: '100000001',
                        relacionDirecta: habilitarEdicion
                    }
                    : item)
                setAccionistas(accionistaEditado)
                var currentData = localStorage.getItem("docsValues")
                currentData = currentData ? JSON.parse(currentData) : {};
                currentData.accionistas = accionistaEditado
                localStorage.setItem("docsValues", JSON.stringify(currentData))
                setAccionistaID('')
                toast.success('Accionista modificado con exito', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    handleClose2()
                    setLoadingAccionistas(false)
                }, 800);
            } else {
                setLoadingAccionistas(false)
            }
        } catch (error) {
            setLoadingAccionistas(false)
        }
    }

    //####Agregar un documento
    const agregarDocumento = () => {
        setDesabilitadoDocumento(true)
        if (selectedFiles.length > 0) {
            var peso = 0
            try {
                for (let index = 0; index < selectedFiles.length; index++) {
                    if (selectedFiles[index].size >= 15000000) {
                        toast.error('El archivo no puede superar los 15 megas', {
                            theme: "dark",
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setDesabilitadoDocumento(false)
                        setSelectedFiles([])
                        return
                    }
                    if (selectedFiles[index].name.includes('#') || selectedFiles[index].name.includes('?')
                        || selectedFiles[index].name.includes('*') || selectedFiles[index].name.includes('/')
                        || selectedFiles[index].name.includes('|') || selectedFiles[index].name.includes('"')
                        || selectedFiles[index].name.includes('<') || selectedFiles[index].name.includes('>')) {
                        toast.error('El nombre del archivo no debe contener caracteres especiales', {
                            theme: "dark",
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setDesabilitadoDocumento(false)
                        setSelectedFiles([])
                        return
                    }
                    if (archivos.filter(item => item.name == selectedFiles[index].name).length > 0) {
                        toast.error('Ya existe un documento cargado con el mismo nombre', {
                            theme: "dark",
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setDesabilitadoDocumento(false)
                        setSelectedFiles([])
                        return
                    }
                    peso += selectedFiles[index].size
                }
                peso += pesoArch
                if (peso >= 20000000) {
                    toast.error('El peso de los archivos adjuntos supera los 20 megas', {
                        theme: "dark",
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setDesabilitadoDocumento(false)
                    setSelectedFiles([])
                    return
                }
                var existeDocumento = false
                var adjuntos = []
                var currentData = localStorage.getItem("docsValues")  //localStorage
                currentData = currentData ? JSON.parse(currentData) : {};  //localStorage
                if (archivos.length > 0) {
                    for (let index = 0; index < archivos.length; index++) {
                        adjuntos.push(archivos[index]);
                    }
                }
                if (selectedFiles.length > 0) {
                    for (let index = 0; index < selectedFiles.length; index++) {
                        selectedFiles[index].documentoId = documentoId
                        adjuntos.push(selectedFiles[index]);
                        if (carpetas.filter(item => item.documentoid == documentoId).length > 0) {
                            existeDocumento = true
                        }
                    }
                }
                if (!existeDocumento && docusCargados > 0) {
                    if (documentosIds !== '') {
                        var docsIds = documentosIds
                        docsIds = docsIds + ', ' + documentoId
                        setDocumentosIds(docsIds)
                        currentData.documentosIds = docsIds  //localStorage
                    } else {
                        setDocumentosIds(documentoId)
                        currentData.documentosIds = documentoId  //localStorage
                    }
                    setDocusCargados(docusCargados + 1)
                    currentData.docusCargados = docusCargados + 1 //localStorage
                    var documentoActualizado = []
                    if (documentosDTOs.length > 0) {
                        documentoActualizado = documentosDTOs.filter(item => item.new_documentacionid === documentoId)
                    }
                    documentoActualizado.map(item => item.documentoCargado = true)
                    documentoActualizado.map(item => item.pesoArchivo = peso)
                    var documentosActualizados = documentosDTOs.map(item => item.new_documentacionid == documentoActualizado[0].new_documentacionid ? documentoActualizado[0] : item)
                    setDocumentosDTOs(documentosActualizados)
                    currentData.documentosDTOs = documentosActualizados //localStorage
                } else if (docusCargados === 0) {
                    setDocumentosIds(documentoId)
                    currentData.documentosIds = documentoId  //localStorage
                    setDocusCargados(docusCargados + 1)
                    currentData.docusCargados = docusCargados + 1 //localStorage
                    var documentoActualizado = []
                    if (documentosDTOs.length > 0) {
                        documentoActualizado = documentosDTOs.filter(item => item.new_documentacionid === documentoId)
                    }
                    documentoActualizado.map(item => item.documentoCargado = true)
                    documentoActualizado.map(item => item.pesoArchivo = peso)
                    var documentosActualizados = documentosDTOs.map(item => item.new_documentacionid == documentoActualizado[0].new_documentacionid ? documentoActualizado[0] : item)
                    setDocumentosDTOs(documentosActualizados)
                    currentData.documentosDTOs = documentosActualizados //localStorage
                }

                if (carpetas.length > 0) {
                    var docsEnCarpeta = carpetas.filter(item => item.documentoid == documentoId)
                    if (docsEnCarpeta.length > 0) {
                        for (let index = 0; index < selectedFiles.length; index++) {
                            var archivo = selectedFiles[index];
                            var cantidadNombres = docsEnCarpeta[0].nombreArchivos.filter(item => item == archivo.name).length
                            if (cantidadNombres == 0) {
                                docsEnCarpeta[0].nombreArchivos.push(archivo.name)
                                let peso = pesoArch > 0 ? pesoArch : 0
                                if (archivo.size > 0) {
                                    peso += archivo.size
                                }
                                setPesoArch(peso)
                            }
                        }
                    } else {
                        var array = carpetas;
                        let pesoArchs = 0
                        for (let index = 0; index < selectedFiles.length; index++) {
                            if (array.filter(item => item.documentoid == documentoId).length == 0) {
                                var carpeta = {
                                    documentoid: documentoId,
                                    nombreArchivos: [selectedFiles[index].name]
                                }
                                array.push(carpeta)
                                pesoArchs = pesoArch
                                if (selectedFiles[index] != null && selectedFiles[index].size != null)
                                    pesoArchs += selectedFiles[index].size
                                setPesoArch(pesoArchs)
                            } else {
                                array.filter(item => item.documentoid == documentoId).map(arc => {
                                    var archivo = selectedFiles[index];
                                    var cantidadNombres = arc.nombreArchivos.filter(item => item == archivo.name).length
                                    if (cantidadNombres == 0) {
                                        arc.nombreArchivos.push(archivo.name)
                                        pesoArchs = pesoArch + selectedFiles[index].size
                                        setPesoArch(pesoArchs)
                                    }
                                })
                            }
                        }
                        dispatch(agregarDocumentoAcarpeta(array))
                    }
                }
                else {
                    var array = [];
                    var pesoArchs = 0;
                    for (let index = 0; index < selectedFiles.length; index++) {
                        if (array.length == 0) {
                            var carpeta = {
                                documentoid: documentoId,
                                nombreArchivos: [selectedFiles[index].name]
                            }
                            array.push(carpeta)
                            pesoArchs += selectedFiles[index].size

                        } else {
                            array.filter(item => item.documentoid == documentoId).map(item => {
                                item.nombreArchivos.push(selectedFiles[index].name)
                                pesoArchs += selectedFiles[index].size
                            })
                        }
                    }
                    setPesoArch(pesoArchs)
                    dispatch(agregarDocumentoAcarpeta(array))
                }
                setArchivos(adjuntos)
                currentData.archivos = adjuntos //localStorage
                localStorage.setItem("docsValues", JSON.stringify(currentData))//localStorage
                toast.success('Documento cargado con exito', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    setDesabilitadoDocumento(false)
                    handleClose()
                    setDatosDocumento("")
                    setSelectedFiles([])
                }, 2000);
            }
            catch (error) {
                toast.error('Hubo un error al subir el archivo.', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setDesabilitadoDocumento(false)
                setSelectedFiles([])
            }
        } else {
            toast.error('Debes elegir un archivo para agregar!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setDesabilitadoDocumento(false)
        }
    }

    const eliminarArchivo = (nombre) => {
        if (archivos.length > 0) {
            var peso = pesoArch
            var archivo = archivos.filter(item => item.name == nombre)
            var i = archivos.indexOf(archivo[0])
            if (i !== -1) {
                peso -= archivo[0].size
                setPesoArch(peso)
                archivos.splice(i, 1);
            }
            setArchivos(archivos)
            // setEliminando(false)
        }

        if (carpetas.length > 0) {
            var carpeta = carpetas.filter(item => item.documentoid == documentoId)
            if (carpeta.length > 0) {
                var i = carpeta[0].nombreArchivos.indexOf(nombre)
                if (i !== -1) {
                    carpeta[0].nombreArchivos.splice(i, 1);
                    if (carpeta[0].nombreArchivos.length == 0) {
                        var posicion = carpetas.indexOf(carpeta[0]);
                        if (posicion !== -1) {
                            carpetas.splice(posicion, 1);
                            setDocusCargados(docusCargados - 1)  //################
                            var documentoActualizado = []
                            documentoActualizado = documentosDTOs.filter(item => item.new_documentacionid === documentoId)
                            documentoActualizado.map(item => item.documentoCargado = false)
                            documentoActualizado.map(item => item.pesoArchivo = 0)
                            var documentosActualizados = documentosDTOs.map(item => item.new_documentacionid == documentoActualizado.new_documentacionid ? documentoActualizado : item)
                            // setValues({ ...values, ["docuentosDTOs"]: documentosActualizados })
                            setDocumentosDTOs(documentosActualizados)
                            var docsIds = ''
                            var documentosCargados = documentosIds.split(',')
                            documentosCargados.forEach(element => {
                                if (element.trim() != documentoId) {
                                    if (docsIds !== '') {
                                        docsIds = docsIds + ', ' + element.trim()
                                    } else {
                                        docsIds = element.trim()
                                    }
                                }
                            })
                            // values.documentosIds = docsIds
                            setDocumentosIds(docsIds)
                        }
                    }
                }
                setCarpeta(carpetas.filter(item => item.documentoid == documentoId))
                dispatch(agregarDocumentoAcarpeta(carpetas))
            }
        }
        setOpenEliminarArchivo(false)
    }

    const eliminarArchivoFiltrado = (nombre, documentoID) => {
        if (archivos.length > 0) {
            var peso = pesoArch
            var archivo = archivos.filter(item => item.name == nombre)
            var i = archivos.indexOf(archivo[0])
            if (i !== -1) {
                peso -= archivo[0].size
                setPesoArch(peso)
                archivos.splice(i, 1);
            }
            setArchivos(archivos)
            // setEliminando(false)
        }

        if (carpetas.length > 0) {
            var carpeta = carpetas.filter(item => item.documentoid == documentoID)
            if (carpeta.length > 0) {
                var i = carpeta[0].nombreArchivos.indexOf(nombre)
                if (i !== -1) {
                    carpeta[0].nombreArchivos.splice(i, 1);
                    if (carpeta[0].nombreArchivos.length == 0) {
                        var posicion = carpetas.indexOf(carpeta[0]);
                        if (posicion !== -1) {
                            carpetas.splice(posicion, 1);
                            setDocusCargados(docusCargados - 1)  //################
                            var documentoActualizado = []
                            documentoActualizado = documentosDTOs.filter(item => item.new_documentacionid === documentoID)
                            documentoActualizado.map(item => item.documentoCargado = false)
                            documentoActualizado.map(item => item.pesoArchivo = 0)
                            var documentosActualizados = documentosDTOs.map(item => item.new_documentacionid == documentoActualizado.new_documentacionid ? documentoActualizado : item)
                            // setValues({ ...values, ["docuentosDTOs"]: documentosActualizados })
                            setDocumentosDTOs(documentosActualizados)
                            var docsIds = ''
                            var documentosCargados = documentosIds.split(',')
                            documentosCargados.forEach(element => {
                                if (element.trim() != documentoID) {
                                    if (docsIds !== '') {
                                        docsIds = docsIds + ', ' + element.trim()
                                    } else {
                                        docsIds = element.trim()
                                    }
                                }
                            })
                            // values.documentosIds = docsIds
                            setDocumentosIds(docsIds)
                        }
                    }
                }
                setCarpeta(carpetas.filter(item => item.documentoid == documentoId))
                dispatch(agregarDocumentoAcarpeta(carpetas))
            }
        }
        setOpenEliminarArchivo(false)
    }

    const reinciarOnboarding = () => {
        localStorage.clear();
        setOpenReiniciarOnboarding(false)
        window.location.reload()
    }

    //estilo del color de los iconos SI O SI DEBEN SER DEL TIPO LINEAR GRADIENT, aun no se como hacer para que sean
    //de un color hexadecimal - recomiendo esta pagina para elegir un color fijo y pasarlo https://cssgradient.io/
    // const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    //     backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    //     zIndex: 1,
    //     color: '#fff',
    //     width: 50,
    //     height: 50,
    //     display: 'flex',
    //     borderRadius: '50%',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     ...(ownerState.active && {
    //         backgroundImage:
    //             'linear-gradient(351deg, rgba(35,43,35,1) 0%, rgba(25,208,11,1) 0%)',
    //         // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    //     }),
    //     ...(ownerState.completed && {
    //         backgroundImage:
    //             'linear-gradient(351deg, rgba(35,43,35,1) 0%, rgba(25,208,11,1) 0%)',
    //     }),
    // }));

    //aca se definen los steps con los iconos a mostrar
    const stepConIcono = (props) => {
        const { active, completed, className } = props;

        const icons = {
            1: <AssignmentIndSharpIcon />,
            2: <AccountCircleSharpIcon />,
            3: <RequestQuoteSharpIcon />,
            4: <DescriptionSharpIcon />,
            5: <ThumbUpAltIcon />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    //style del modal
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 400,
        bgcolor: 'background.default',
        border: '2px solid #000',
        boxShadow: 24,
        p: 2,
    };


    const personeriaOnChange = (event) => {
        setPerson(event.target.value)
    }

    const personeriaOpciones = [
        { value: '100000000', label: 'Jurídica' },
        { value: '100000001', label: 'Humana' }
    ]


    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg,rgb(136,212,76) 0%,rgb(34,174,15)50%,rgb(136,212,76) 100%)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg,rgb(136,212,76) 0%,rgb(34,174,15)50%,rgb(136,212,76) 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[600],
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot2 = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[600],
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage:
                'linear-gradient( 95deg,rgb(136,212,76) 0%,rgb(34,174,15)50%,rgb(136,212,76) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundImage:
                'linear-gradient( 95deg,rgb(136,212,76) 0%,rgb(34,174,15)50%,rgb(136,212,76) 100%)',
        }),
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: <ManageAccountsIcon />,
            2: <AssignmentIndIcon />,
            3: <RequestQuoteIcon />,
            4: <DescriptionIcon />,
            5: <ThumbUpAltIcon />
        };

        return (
            <ColorlibStepIconRoot2 ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot2>
        );
    }

    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-50% + 18px)',
            right: 'calc(50% + 18px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#00c853',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#00c853',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.grey[900],
            borderTopWidth: 4,
            borderRadius: 2,
        },
    }));

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.grey[900],
        display: 'flex',
        height: 24,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#00c853',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#00c853',
            zIndex: 1,
            fontSize: 30,
        },
        '& .QontoStepIcon-circle': {
            width: 16,
            height: 16,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };

    const onChange = () => {
        setStorage(formData);
    };

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[600],
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage:
                'linear-gradient( 95deg,rgb(21,113,9) 0%,rgb(21,113,9)50%,rgb(9,121,97) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundImage:
                'linear-gradient( 95deg,rgb(21,113,9) 0%,rgb(21,113,9)50%,rgb(9,121,97) 100%)',
        }),
    }));

    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };

    const theme = useTheme();

    const porcentajeF = (value) => {
        setPercentaje(value)
    }

    return (
        <>
            {/* container maxwidth sm/md/xl para el tamaño */}
            <Container component="main" sx={{ mb: 4, fontSize: 22 }}>
                {/* <Box
                    sx={{
                        p: 2,
                        bgcolor: 'background.default',
                        display: 'grid',
                        gridTemplateColumns: { md: '1fr 1fr' },
                        gap: 2,
                    }}
                > */}
                <Paper elevation={8} sx={{
                    my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 },
                    // minHeight: 600 
                }}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} sx={{ pt: 3, pb: 5, fontWeight: 900 }}>
                            {steps.map((label) => (
                                <Step key={label} sx={{ fontSize: 18, p: 0 }}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ fontWeight: 900, color: `${theme.palette.text.secondary}`, fontSize: 22 }}>
                                        <Typography sx={{
                                            fontSize: {
                                                lg: 20,
                                                md: 15,
                                                sm: 12,
                                                xs: 10
                                            }, fontWeight: 600
                                        }}>
                                            {label}
                                        </Typography>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Stack>
                    <React.Fragment>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    p: 1,
                                    m: 1,
                                    borderRadius: 1,
                                }}>
                                    <Collapse in={mostrarIconoFinal} timeout={{ enter: 800 }}>
                                        <IconButton fontSize="inherit" sx={{ fontSize: '120px', color: "#388e3c" }}>
                                            <BeenhereOutlinedIcon sx={{ fontSize: '80px' }} />
                                        </IconButton>
                                    </Collapse>
                                </Box>
                                <Collapse in={mostrarTextoFinal} timeout={{ appear: 400, enter: 800 }}>
                                    <Typography variant="subtitle1" align="center">
                                        Felicitaciones! has completado el primer paso. En breve nos comunicaremos contigo vía correo electrónico para informarte del estado de tu solicitud. Cualquier duda podés enviar un correo informando nombre completo o razón social y Cuit/Cuil al siguiente correo administracion@sgroneclick.com
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        p: 1,
                                        m: 1,
                                        mt: 5,
                                        borderRadius: 1,
                                    }}>
                                        <Stack direction="row" spacing={2}>
                                            <Button variant="outlined" color="success" onClick={() => reinciarOnboarding()}>
                                                Finalizar onboarding
                                            </Button>
                                        </Stack>
                                    </Box>
                                </Collapse>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <FormProvider {...methods}>
                                    {/* <Paper elevation={12}> */}
                                    <form onChange={onChange}>
                                        <div>
                                            {mostrarFormularios(activeStep)}
                                        </div>
                                        <Grid container>
                                            <Grid xs={6}>
                                                {
                                                    activeStep != 4 ?
                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                            <Tooltip title={<Typography sx={{ color: '#fff' }}>Reiniciar Onboarding</Typography>} sx={{ mt: 3 }} >
                                                                <IconButton>
                                                                    <ReplayIcon onClick={() => setOpenReiniciarOnboarding(true)} disabled={desabilitadoEnvio} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {/* <Tooltip title={<Typography sx={{ color: '#f57c00' }}>Cambiar Tema</Typography>} sx={{ mt: 3 }}>
                                                                <IconButton onClick={props.setearColor}>
                                                                    <Brightness6Rounded disabled={desabilitadoEnvio} />
                                                                </IconButton>
                                                            </Tooltip> */}
                                                        </Box>
                                                        : null
                                                }
                                            </Grid>
                                            <Grid xs={6}>
                                                {
                                                    activeStep != 4 ?
                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                            {activeStep !== 0 && (
                                                                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }} disabled={desabilitadoEnvio}>
                                                                    Atras
                                                                </Button>
                                                            )}

                                                            {activeStep === steps.length - 2 ? (
                                                                <Button
                                                                    variant="contained"
                                                                    // color="primary"
                                                                    onClick={handleSubmit(submitDatos)}
                                                                    disabled={desabilitadoEnvio}
                                                                    sx={{ mt: 3, ml: 1, color: '#24292e', }}
                                                                >Enviar</Button>
                                                            ) : (
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={handleNext}
                                                                    sx={{ mt: 3, ml: 1, color: '#24292e', }}
                                                                >
                                                                    Siguiente</Button>
                                                            )}
                                                        </Box>
                                                        : null
                                                }
                                            </Grid>
                                        </Grid>
                                    </form>
                                    {/* </Paper> */}
                                </FormProvider>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </Paper>
                {/* </Box> */}
                {/* Modal documentos */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography id="alert-dialog-title" sx={{ fontSize: "1.2rem" }}>
                                Cargar documentación
                            </Typography>
                            <IconButton
                                edge="end"
                                color="warning"
                                onClick={handleClose}
                                aria-label="close"
                                sx={{ mx: 1 }}
                            >
                                <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>}>
                                    <CloseIcon />
                                </Tooltip>
                            </IconButton>
                        </Box>
                        <Box>
                            {/* <Typography gutterBottom variant="body1">
                                Documento:
                            </Typography> */}
                            <Chip label={nombreDocumento} sx={{ mt: 1, mb: 1 }} />
                        </Box>
                        <Box sx={{ py: 2 }}>
                            <MuiFileInput
                                value={selectedFiles}
                                multiple
                                label="Adjuntar documento"
                                onChange={changeHandler}
                            />
                        </Box>
                        <Button
                            sx={{ mt: 1, mb: 1 }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={agregarDocumento}
                            disabled={desabilitadoDocumento}
                        >
                            Subir archivo(s)
                        </Button>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                            <Box sx={{ height: '100%' }} >
                                <WarningAmberIcon color="warning" sx={{ mr: 1 }} />
                            </Box>
                            <Box sx={{ height: '100%' }} >
                                <Typography>
                                    El tamaño máximo permitido por archivo es de 15 MB
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    {/* <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Cargar documentación
                        </Typography>
                        <Divider />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <Typography gutterBottom variant="body1">
                                Documento:
                            </Typography>
                            <Chip label={nombreDocumento} sx={{ mt: 2, mb: 2 }} />
                            <TextField required id="standard-basic" variant="standard" type="file" onChange={changeHandler} />
                            <Box sx={{ mt: 3, mb: 2, position: 'relative' }}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    onClick={agregarDocumento}
                                    disabled={desabilitadoDocumento}
                                >
                                    Cargar Archivo
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: '#379634',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Typography>
                    </Box> */}
                </Modal>
                {/* Modal Carpeta de Documentos */}
                <Modal
                    open={openCarpeta}
                    onClose={handleCloseCarpeta}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography id="alert-dialog-title" sx={{ fontSize: "1.1rem" }}>
                                {nombreDocumento}
                            </Typography>
                            <IconButton
                                edge="end"
                                color="warning"
                                onClick={handleCloseCarpeta}
                                aria-label="close"
                                sx={{ mx: 1 }}
                            >
                                <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar Carpeta</Typography>}>
                                    <CloseIcon />
                                </Tooltip>
                            </IconButton>
                        </Box>
                        <Divider />
                        <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                            {/* <Typography gutterBottom variant="body1">
                                Documento:
                            </Typography> */}
                            <Chip label="Documentos" sx={{ mt: 1, mb: 1 }} />
                            {
                                carpeta.length > 0 ?
                                    <Box sx={{ flexGrow: 1, maxWidth: 752, m: 0 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Demo>
                                                    <List dense={dense} fullWidth sx={{ m: 0, p: 0 }}>
                                                        {
                                                            carpeta[0].nombreArchivos.map(archivo => {
                                                                return (
                                                                    <ListItem
                                                                        sx={{ m: 0, p: 0, pt: 1 }}
                                                                        fullWidth
                                                                        secondaryAction={
                                                                            <IconButton sx={{ pt: 1 }} edge="end" aria-label="delete" color="warning" onClick={() => prepararModalEliminarArchivo(archivo)}>
                                                                                <Tooltip title={<Typography sx={{ color: '#fff' }}>Eliminar Archivo</Typography>}>
                                                                                    <DeleteIcon />
                                                                                </Tooltip>
                                                                            </IconButton>
                                                                        }
                                                                    >
                                                                        <ListItemAvatar>
                                                                            <Avatar>
                                                                                <InsertDriveFileIcon />
                                                                            </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText fullWidth
                                                                            primary={archivo}
                                                                        // secondary={secondary ? 'Secondary text' : null}
                                                                        />
                                                                    </ListItem>
                                                                );
                                                            })
                                                        }
                                                    </List>
                                                </Demo>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    :
                                    null
                            }
                            {/* <TextField required id="standard-basic" variant="standard" type="file" onChange={changeHandler} />
                            <Box sx={{ mt: 3, mb: 2, position: 'relative' }}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    onClick={agregarDocumento}
                                >
                                    Cargar Archivo
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: '#379634',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box> */}
                        </Typography>
                    </Box>
                </Modal>
                {/*Modal eliminar Archivo*/}
                <Modal
                    open={openEliminarArchivo}
                    onClose={handleCloseEliminarArchivo}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Typography id="modal-modal-description" sx={{ mb: 2, pb: 2 }}>
                                ¿Desea eliminar el archivo {nombreDocumento}?
                            </Typography>
                        </Box>
                        <Grid container sx={{ pt: 2 }}>
                            <Grid xs={6}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Button color="error" variant="contained" onClick={() => setOpenEliminarArchivo(false)}>Cancelar</Button>
                                </Box>
                            </Grid>
                            <Grid xs={6}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Button color="success" alignItems="center" variant="contained" onClick={() => eliminarArchivo(archivoSeleccionado)}>Confirmar</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/*Modal eliminar Accionista*/}
                <Modal
                    open={openEliminarAccionista}
                    onClose={handleCloseEliminarAccionista}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Typography id="modal-modal-description" sx={{ mb: 2, pb: 2 }}>
                                ¿Desea eliminar el accionista ?
                            </Typography>
                        </Box>
                        <Grid container>
                            <Grid xs={6}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Button color="error" variant="contained" onClick={() => setOpenEliminarAccionista(false)}>Cancelar</Button>
                                </Box>
                            </Grid>
                            <Grid xs={6}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Button color="success" alignItems="center" variant="contained" onClick={() => eliminarAccionista(accionistaID)}>Confirmar</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/*Modal eliminar Archivo*/}
                <Modal
                    open={openReiniciarOnboarding}
                    onClose={handleCloseReiniciarOnboarding}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Typography id="modal-modal-description" sx={{ mb: 2, pb: 2 }}>
                                ¿Desea reiniciar el onboarding?
                            </Typography>
                        </Box>
                        <Grid container>
                            <Grid xs={6}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Button color="error" variant="contained" onClick={() => setOpenReiniciarOnboarding(false)}>Cancelar</Button>
                                </Box>
                            </Grid>
                            <Grid xs={6}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Button color="success" alignItems="center" variant="contained" onClick={() => reinciarOnboarding()}>Confirmar</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal accionista */}
                {/* <Dialog open={open2} onClose={handleClose2} fullWidth={true} maxWidth="xs"> */}
                <Modal
                    open={open2} onClose={handleClose2}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        {
                            esActualizacion ?
                                <>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                                        <Typography id="alert-dialog-title" sx={{ fontSize: "1.2rem" }}>
                                            Actualizar Accionista
                                        </Typography>
                                        <IconButton
                                            edge="end"
                                            color="warning"
                                            onClick={handleClose2}
                                            aria-label="close"
                                        // sx={{ mx: 1 }}
                                        >
                                            <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>}>
                                                <CloseIcon />
                                            </Tooltip>
                                        </IconButton>
                                    </Box>
                                    <DialogContent>
                                        <InputLabel id="demo-simple-select-standard-label">Personeria</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            label="Personeria"
                                            value={person}
                                            onChange={personeriaOnChange}
                                            fullWidth
                                            variant="standard"
                                            disabled={habilitarEdicion}
                                        >
                                            {personeriaOpciones.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {
                                            person.length > 0 && person === '100000001' ?
                                                <>
                                                    <TextField
                                                        value={nombre}
                                                        margin="dense"
                                                        id="Nombre"
                                                        label="Nombre"
                                                        type="text"
                                                        fullWidth
                                                        variant="standard"
                                                        onChange={e => setNombre(e.target.value)}
                                                        disabled={habilitarEdicion}
                                                    />
                                                    <TextField
                                                        sx={{ mt: 2 }}
                                                        autoFocus
                                                        value={apellido}
                                                        margin="dense"
                                                        id="Apellido"
                                                        label="Apellido"
                                                        type="text"
                                                        fullWidth
                                                        variant="standard"
                                                        onChange={e => setApellido(e.target.value)}
                                                        disabled={habilitarEdicion}
                                                    />
                                                </>
                                                :
                                                null
                                        }
                                        {
                                            person.length > 0 && person === '100000000' ?
                                                <>
                                                    <TextField
                                                        sx={{ mt: 2 }}
                                                        value={razonSoc}
                                                        margin="dense"
                                                        id="RazonSocial"
                                                        label="Razón Social"
                                                        type="text"
                                                        fullWidth
                                                        variant="standard"
                                                        onChange={e => setRazonSoc(e.target.value)}
                                                        disabled={habilitarEdicion}
                                                    />
                                                </> : null
                                        }
                                        <TextField
                                            sx={{ mt: 2 }}
                                            value={cuitCUIL}
                                            margin="dense"
                                            id="CuitCuil"
                                            label="Cuit/Cuil"
                                            type="number"
                                            fullWidth
                                            variant="standard"
                                            onChange={e => setcuitCUIL(e.target.value)}
                                            disabled={habilitarEdicion}
                                        />
                                        <FormHelperText sx={{ color: '#f57c00' }} id="outlined-weight-helper-text">
                                            ! Sin espacios ni guiones !
                                        </FormHelperText>
                                        <CurrencyTextField
                                            sx={{ mt: 1 }}
                                            fullWidth
                                            label="% de participación"
                                            variant="standard"
                                            value={porcentajeAux}
                                            currencySymbol="%"
                                            outputFormat="string"
                                            color="success"
                                            // onChange={e => setPercentaje(e.target.value)}
                                            onChange={e => porcentajeF(e.target.value)}
                                            required={true}
                                            // helperText={formState.errors[name] ? formState.errors[name].message : null}
                                            // error={Boolean(formState.errors && formState.errors[name])}
                                            placeholder='0.00'
                                        // {...restProps}
                                        />
                                        {/* <TextField
                                            sx={{ mt: 2 }}
                                            value={percentaje}
                                            margin="dense"
                                            id="Porcentaje"
                                            label="% de participación"
                                            type="number"
                                            inputProps={{ min: 0, max: 100 }}
                                            fullWidth
                                            variant="standard"
                                            onChange={e => setPercentaje(e.target.value)}
                                        /> */}
                                    </DialogContent>

                                    <DialogActions>
                                        <Box sx={{ mt: 3, mb: 2, position: 'relative' }}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                onClick={modificarAccionista}
                                                disabled={loadingAccionistas}
                                            >
                                                Modificar accionista
                                            </Button>
                                            {loading && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: '#379634',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </DialogActions>
                                </>
                                :
                                <>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                                        <Typography id="alert-dialog-title" sx={{ fontSize: "1.2rem" }}>
                                            Agregar Accionista
                                        </Typography>
                                        <IconButton
                                            edge="end"
                                            color="warning"
                                            onClick={handleClose2}
                                            aria-label="close"
                                        // sx={{ mx: 1 }}
                                        >
                                            <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>}>
                                                <CloseIcon />
                                            </Tooltip>
                                        </IconButton>
                                    </Box>
                                    <DialogContent sx={{ px: 2 }}>
                                        <InputLabel id="demo-simple-select-standard-label">Personeria</InputLabel>
                                        <Select
                                            autoFocus
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            label="Personeria"
                                            value={person}
                                            onChange={personeriaOnChange}
                                            fullWidth
                                            variant="standard"
                                        >
                                            {personeriaOpciones.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {/* input-juridica */}
                                        {
                                            person.length > 0 && person === '100000001' ?
                                                <>
                                                    <TextField
                                                        sx={{ mt: 2 }}
                                                        value={nombre}
                                                        margin="dense"
                                                        id="Nombre"
                                                        label="Nombre"
                                                        type="text"
                                                        fullWidth
                                                        variant="standard"
                                                        onChange={e => setNombre(e.target.value)}
                                                    />
                                                    <TextField
                                                        sx={{ mt: 2 }}
                                                        value={apellido}
                                                        margin="dense"
                                                        id="Apellido"
                                                        label="Apellido"
                                                        type="text"
                                                        fullWidth
                                                        variant="standard"
                                                        onChange={e => setApellido(e.target.value)}
                                                    />
                                                </>
                                                :
                                                person.length > 0 && person === '100000000' ?
                                                    <>
                                                        <TextField
                                                            sx={{ mt: 2 }}
                                                            value={razonSoc}
                                                            margin="dense"
                                                            id="RazonSocial"
                                                            label="Razón Social"
                                                            type="text"
                                                            fullWidth
                                                            variant="standard"
                                                            onChange={e => setRazonSoc(e.target.value)}
                                                        />
                                                    </>
                                                    :
                                                    null
                                        }
                                        <TextField
                                            sx={{ mt: 2 }}
                                            value={cuitCUIL}
                                            margin="dense"
                                            id="CuitCuil"
                                            label="Cuit/Cuil"
                                            type="number"
                                            fullWidth
                                            variant="standard"
                                            onChange={e => setcuitCUIL(e.target.value)}
                                        />
                                        <FormHelperText sx={{ color: '#f57c00' }} id="outlined-weight-helper-text">
                                            ! Sin espacios ni guiones !
                                        </FormHelperText>
                                        <CurrencyTextField
                                            sx={{ mt: 1 }}
                                            fullWidth
                                            label="% de participación"
                                            variant="standard"
                                            value={porcentajeAux}
                                            currencySymbol="%"
                                            outputFormat="string"
                                            color="success"
                                            // onChange={e => setPercentaje(e.target.value)}
                                            onChange={e => porcentajeF(e.target.value)}
                                            required={true}
                                            // helperText={formState.errors[name] ? formState.errors[name].message : null}
                                            // error={Boolean(formState.errors && formState.errors[name])}
                                            placeholder='0.00'
                                        // {...restProps}
                                        />
                                        {/* <TextField
                                            sx={{ mt: 2 }}
                                            value={percentaje}
                                            margin="dense"
                                            id="Porcentaje"
                                            label="% de participación"
                                            type="number"
                                            inputProps={{ min: 0, max: 100 }}
                                            fullWidth
                                            variant="standard"
                                            onChange={e => setPercentaje(e.target.value)}
                                        /> */}
                                    </DialogContent>
                                    <DialogActions>
                                        <Box sx={{ mt: 3, mb: 2, position: 'relative' }}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                onClick={crearAccionista}
                                                disabled={loadingAccionistas}
                                            >
                                                Cargar accionista
                                            </Button>
                                            {loading && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: '#379634',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </DialogActions>
                                </>
                        }
                    </Box>
                </Modal>
                {/* </Dialog> */}
            </Container>
        </>

    )
}

export default SolicitudAlta