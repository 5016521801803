import axios from 'axios'
import { Entidad, UrlApi, UrlApiDynamics } from '../Keys'
import {
    // ToastContainer, 
    toast
} from 'react-toastify';

const dataInicial = {
    loading: false,
    loadingCondiciones: false,
    loadingActividades: false,
    loadingTiposDocumentos: false,
    loadingReferidor: false,
    loadingOnboarding: true,
    retrieveTipoDocumento: false,
    retrieveActividadAFIP: false,
    retrieveCondicionAFIP: false,
    retrieveReferidor: false,
    estadoOnboarding: '',
    porcentajeOnboarding: 0,
    robot: false,
    referidores: [],
    tiposDocumentos: [],
    actividades: [],
    condiciones: [],
    documentos: [],
    carpetas: [],
    condicionesAFIP: [],
    documentosNoCargados: [],
    razonSocial: ''
}

const LOADING = 'LOADING'
const LOADING_CONDICIONES_AFIP = 'LOADING_CONDICIONES_AFIP'
const LOADING_CONDICIONES = 'LOADING_CONDICIONES'
const LOADING_TIPODOCUMENTO = 'LOADING_TIPODOCUMENTO'
const LOADING_ACTIVIDADES = 'LOADING_ACTIVIDADES'
const LOADING_REFERIDORES = 'LOADING_REFERIDORES'
const ERROR = 'ERROR'
const ERROR_CONDICIONES_AFIP = "ERROR_CONDICIONES_AFIP"
const ERROR_TIPODOCUMENTO = 'ERROR_TIPODOCUMENTO'
const ERROR_CONDICIONES = 'ERROR_CONDICIONES'
const ERROR_ACTIVIDADES = 'ERROR_ACTIVIDADES'
const ERROR_REFERIDORES = 'REFERIDORES'
const TODOS_TIPDOCUMENTO_EXITO = 'TODOS_TIPDOCUMENTO_EXITO'
const TODAS_ACTIVIDADES_EXITO = 'TODAS_ACTIVIDADES_EXITO'
const TODAS_CONDICIONES_EXITO = 'TODAS_CONDICIONES_EXITO'
const TODOS_DOCUMENTO_EXITO = 'TODOS_DOCUMENTO_EXITO'
const TODOS_CONDICIONES_EXITO = 'TODOS_CONDICIONES_EXITO'
const TODOS_REFERIDORES_EXITO = 'TODOS_REFERIDORES_EXITO'
const CARPETAS_EXITO = 'CARPETAS_EXITO'
const ONBOARDING_EXITO = 'ONBOARDING_EXITO'
const ONBOARDING_ERROR = 'ONBOARDING_ERROR'
const LOADING_ONBOARDING = 'LOADING_ONBOARDING'
const PORCENTAJE_ONBOARDING = 'PORCENTAJE_ONBOARDING'
const ROBOT = 'ROBOT'

export default function datosReducers(state = dataInicial, action) {
    switch (action.type) {
        case TODOS_TIPDOCUMENTO_EXITO:
            return { ...state, tiposDocumentos: action.payload, loading: action.loading, retrieveTipoDocumento: action.retrieveTipoDocumento }
        case TODAS_ACTIVIDADES_EXITO:
            return { ...state, actividades: action.payload, loading: action.loading, retrieveActividadAFIP: action.retrieveActividadAFIP }
        case TODAS_CONDICIONES_EXITO:
            return { ...state, condiciones: action.payload, loading: action.loading, retrieveCondicionAFIP: action.retrieveCondicionAFIP }
        case TODOS_DOCUMENTO_EXITO:
            return { ...state, documentos: action.payload, loading: action.loading }
        case TODOS_CONDICIONES_EXITO:
            return { ...state, condicionesAFIP: action.payload, loadingCondiciones: action.loadingCondiciones }
        case CARPETAS_EXITO:
            return { ...state, carpetas: action.payload }
        case ONBOARDING_EXITO:
            return { ...state, estadoOnboarding: action.estadoOnboarding, loadingOnboarding: action.loadingOnboarding, porcentajeOnboarding: action.porcentajeOnboarding, documentosNoCargados: action.documentosNoCargados, razonSocial: action.razonSocial }
        case ONBOARDING_ERROR:
            return { ...state, estadoOnboarding: action.estadoOnboarding, loadingOnboarding: action.loadingOnboarding, porcentajeOnboarding: action.porcentajeOnboarding, documentosNoCargados: [], razonSocial: "" }
        case LOADING_ONBOARDING:
            return { ...state, loadingOnboarding: action.loadingOnboarding, estadoOnboarding: action.estadoOnboarding, porcentajeOnboarding: action.porcentajeOnboarding, documentosNoCargados: [], razonSocial: "" }
        case PORCENTAJE_ONBOARDING:
            return { ...state, porcentajeOnboarding: action.porcentajeOnboarding }
        case ROBOT:
            return { ...state, robot: action.robot }
        case ERROR_CONDICIONES:
            return { ...state, loadingCondiciones: action.loadingCondiciones }
        case LOADING_TIPODOCUMENTO:
            return { ...state, loadingTiposDocumentos: action.loadingTiposDocumentos, retrieveTipoDocumento: action.retrieveTipoDocumento }
        case ERROR_TIPODOCUMENTO:
            return { ...state, loadingTiposDocumentos: action.loadingTiposDocumentos, retrieveTipoDocumento: action.retrieveTipoDocumento }
        case LOADING_ACTIVIDADES:
            return { ...state, loadingActividades: action.loadingActividades, retrieveActividadAFIP: action.retrieveActividadAFIP }
        case ERROR_ACTIVIDADES:
            return { ...state, loadingActividades: action.loadingActividades, retrieveActividadAFIP: action.retrieveActividadAFIP }
        case LOADING_REFERIDORES:
            return { ...state, loadingReferidor: action.loadingReferidor, retrieveReferidor: action.retrieveReferidor }
        case TODOS_REFERIDORES_EXITO:
            return { ...state, referidores: action.payload, loadingReferidor: action.loadingReferidor, retrieveReferidor: action.retrieveReferidor }
        case ERROR_REFERIDORES:
            return { ...state, loadingReferidor: action.loadingReferidor, retrieveReferidor: action.retrieveReferidor }
        case ERROR_CONDICIONES_AFIP:
            return { ...state, retrieveCondicionAFIP: action.retrieveCondicionAFIP }
        case LOADING_CONDICIONES_AFIP:
            return { ...state, retrieveCondicionAFIP: action.retrieveCondicionAFIP }
        default:
            return { ...state }
    }
}

export const obtenerTipoDeDocumentos = (token) => async (dispatch) => {
    dispatch({
        type: LOADING_TIPODOCUMENTO,
        loadingTiposDocumentos: true,
        retrieveTipoDocumento: false
    })

    try {
        var entidad = 'new_tipodedocumentos'
        var fetch = "<fetch mapping='logical' distinct='false'>" +
            "<entity name='new_tipodedocumento'>" +
            "<attribute name='new_name' />" +
            "<attribute name='new_codigo' />" +
            "<attribute name='new_tipodedocumentoid' />" +
            "<attribute name='new_onboarding' />" +
            "<order attribute='new_name' descending='false' />" +
            "<filter type='and'>" +
            "<condition attribute='statecode' operator='eq' value='0' />" +
            "<condition attribute='new_onboarding' operator='eq' value='1' />" +
            "</filter>" +
            "</entity>" +
            "</fetch>";

        const response = await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
        dispatch({
            type: TODOS_TIPDOCUMENTO_EXITO,
            payload: response.data,
            loadingTiposDocumentos: false,
            retrieveTipoDocumento: true
        })
    }
    catch (error) {
        dispatch({
            type: ERROR_TIPODOCUMENTO,
            loadingTiposDocumentos: false,
            retrieveTipoDocumento: true
        })
    }
}

export const obtenerCondicionImpositiva = (token) => async (dispatch) => {
    dispatch({
        type: LOADING_CONDICIONES_AFIP,
        loading: true,
        retrieveCondicionAFIP: false
    })

    try {

        var entidad = 'new_condiciondeinscipcionanteafips'
        var fetch = "<fetch mapping='logical' distinct='false'>" +
            "<entity name='new_condiciondeinscipcionanteafip'>" +
            "<attribute name='new_name' />" +
            "<attribute name='new_condiciondeinscipcionanteafipid' />" +
            "<filter type='and'>" +
            "<condition attribute='statecode' operator='eq' value='0' />" +
            "</filter>" +
            "</entity>" +
            "</fetch>";

        // const response = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
        const response = await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
        dispatch({
            type: TODAS_CONDICIONES_EXITO,
            payload: response.data,
            loading: false,
            retrieveCondicionAFIP: true
        })
    }
    catch (error) {
        dispatch({
            type: ERROR_CONDICIONES_AFIP,
            loading: false,
            retrieveCondicionAFIP: true
        })
    }
}

export const obtenerActividadesAFIP = (token) => async (dispatch) => {
    dispatch({
        type: LOADING_ACTIVIDADES,
        loadingActividades: true,
        retrieveActividadAFIP: false
    })

    try {
        var entidad = "new_actividadafips"
        var fetch = "<fetch mapping='logical' distinct='false'>" +
            "<entity name='new_actividadafip'>" +
            "<attribute name='new_name' />" +
            "<attribute name='new_codigo' />" +
            "<attribute name='new_actividadafipid' />" +
            "<order attribute='new_name' descending='false' />" +
            "<filter type='and'>" +
            "<condition attribute='statecode' operator='eq' value='0' />" +
            "</filter>" +
            "</entity>" +
            "</fetch>";

        const response = await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
        dispatch({
            type: TODAS_ACTIVIDADES_EXITO,
            payload: response.data,
            loadingActividades: false,
            retrieveActividadAFIP: true
        })
    }
    catch (error) {
        dispatch({
            type: ERROR_ACTIVIDADES,
            loadingActividades: false,
            retrieveActividadAFIP: true
        })
    }
}

export const obtenerDocumentosOnboardingFETCH = (token) => async (dispatch) => {
    dispatch({
        type: LOADING,
        loading: true
    })
    var entidad = "new_documentacions"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='new_documentacion'>" +
        "<attribute name='new_documentacionid'/>" +
        "<attribute name='new_name'/> " +
        "<attribute name='new_codigo'/> " +
        "<attribute name='new_condicionimpositiva'/> " +
        "<attribute name='new_descripcion'/> " +
        "<attribute name='new_estadodelsocio'/> " +
        "<attribute name='new_grupoeconomico'/> " +
        "<attribute name='new_solicituddealta'/>" +
        "<attribute name='new_personeria'/> " +
        "<attribute name='new_tipodedocumento'/> " +
        "<attribute name='new_tipodefiador'/> " +
        "<attribute name='new_urlplantilla'/> " +
        "<attribute name='new_onboarding'/> " +
        "<attribute name='statecode'/> " +
        "<attribute name='new_requeridoenportal'/> " +
        "<order attribute ='new_codigo' descending='false' />" +
        "<filter type='and'>" +
        "<condition attribute='new_onboarding' operator='eq' value='1' />" +
        "</filter>" +
        // "<link-entity name='new_documentacion' from='new_documentacionid' to='new_documentoid' link-type='outer' alias='documento'>" +
        // "<attribute name='new_urlplantilla'/> " +
        // "<attribute name='new_descripcion'/> " +
        // "</link-entity>" +
        "</entity>" +
        "</fetch>"
    try {
        const response = await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
        dispatch({
            type: TODOS_DOCUMENTO_EXITO,
            payload: response.data,
            loading: false
        })
    }
    catch (error) {
        // console.log(error)
        dispatch({
            type: ERROR,
            loading: false
        })
    }
}

export const obtenerCondicionesDeInscripcionFETCH = (token) => async (dispatch) => {
    dispatch({
        type: LOADING_CONDICIONES,
        loadingCondiciones: true
    })

    var entidad = "new_new_documentacion_new_condiciondeinscipset"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='new_new_documentacion_new_condiciondeinscip'>" +
        "<attribute name='new_new_documentacion_new_condiciondeinscipid'/> " +
        "<attribute name='new_documentacionid'/> " +
        "<attribute name='new_condiciondeinscipcionanteafipid'/> " +
        "</entity>" +
        "</fetch>"
    try {
        const response = await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
        dispatch({
            type: TODOS_CONDICIONES_EXITO,
            payload: response.data,
            loadingCondiciones: false
        })
    }
    catch (error) {
        dispatch({
            type: ERROR_CONDICIONES,
            loadingCondiciones: false
        })
    }
}

export const obtenerReferidor = (token) => async (dispatch) => {
    dispatch({
        type: LOADING_REFERIDORES,
        loadingReferidor: true,
        retrieveReferidor: false
    })

    var entidad = "accounts"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='account'>" +
        "<attribute name='name'/> " +
        "<attribute name='new_nmerodedocumento'/> " +
        "<attribute name='accountid'/> " +
        "<order attribute='name' descending='false' />" +
        "<filter type='and'>" +
        "<condition attribute='new_relacionamientoconlasgr' operator='contain-values'>" +
        "<value>100000004</value>" +
        "</condition>" +
        "</filter>" +
        "</entity>" +
        "</fetch>"
    try {
        const response = await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
        dispatch({
            type: TODOS_REFERIDORES_EXITO,
            payload: response.data,
            loadingReferidor: false,
            retrieveReferidor: true
        })
    }
    catch (error) {
        dispatch({
            type: ERROR_REFERIDORES,
            loadingReferidor: false,
            retrieveReferidor: true
        })
    }
}

export const agregarDocumentoAcarpeta = (carpetas) => (dispatch) => {
    try {
        dispatch({
            type: CARPETAS_EXITO,
            payload: carpetas
        })
    } catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const cargarSolicitudDeAlta = (solicitud, files, token) => async (dispatch) => {
    dispatch({
        type: LOADING_ONBOARDING,
        loadingOnboarding: true,
        porcentajeOnboarding: 5,
        estadoOnboarding: "LOADING"
    })

    try {
        var documentacionPorCuenta = solicitud.documentosDTOs
        var solicitud_id
        var accountid
        var documentosNoCargados = []

        const onboarding = () => new Promise(async (resolve, reject) => {
            let invitacion = false

            await validarUsuarioEnDynamics(solicitud, token)
                .then(resp => {
                    if (resp.data != null) {
                        if (resp.data.length > 0 && resp.data[0].new_invitacion != undefined) {
                            invitacion = resp.data[0].new_invitacion
                            if (!invitacion) {
                                crearExcepcion(`La pyme que desea registrar ya existe en el sistema. 
                                    ${solicitud.nombreRazonSocial} cuit: ${solicitud.cuitCuilCdi} Correo: ${solicitud.email}`, token)
                                reject("La pyme que desea registrar ya existe en el sistema")
                            }
                        } else if (resp.data.length > 0 && (resp.data[0].new_invitacion == undefined ||
                            resp.data[0].new_invitacion == false)) {
                            crearExcepcion(`La pyme que desea registrar ya existe en el sistema. 
                                    ${solicitud.nombreRazonSocial} cuit: ${solicitud.cuitCuilCdi} Correo: ${solicitud.email}`, token)
                            reject("La pyme que desea registrar ya existe en el sistema")
                        }
                    }
                })
                .catch(error => {
                    reject(error)
                })
            dispatch({
                type: PORCENTAJE_ONBOARDING,
                porcentajeOnboarding: 15
            })
            await crearSolicitud(solicitud, invitacion, token)
                .then(resp => {
                    if (resp.data != null) {
                        solicitud_id = resp.data
                    }
                })
                .catch(e => {
                    crearExcepcion(`Hubo un error al enviar la solicitud. Por favor, pongase en contacto con nosotros. 
                    ${solicitud.nombreRazonSocial} cuit: ${solicitud.cuitCuilCdi} Correo: ${solicitud.email} - ${e}`, token)
                    reject("Hubo un error al enviar la solicitud. Por favor, pongase en contacto con nosotros.")
                })

            if (solicitud_id !== undefined && solicitud_id !== null) {
                dispatch({
                    type: PORCENTAJE_ONBOARDING,
                    porcentajeOnboarding: 40
                })
                await ObtenerAccountid(solicitud_id, token)
                    .then(resp => {
                        if (resp.data != null && resp.data.length > 0) {
                            accountid = resp.data[0].accountid
                        }
                    })
                    .catch(e => {
                        crearExcepcion(`Hubo un error al enviar la solicitud. Por favor, pongase en contacto con nosotros. 
                         ${solicitud.nombreRazonSocial} cuit: ${solicitud.cuitCuilCdi} Correo: ${solicitud.email} - ${e}`, token)
                        reject("Hubo un error al enviar la solicitud. Por favor, pongase en contacto con nosotros.")
                    })
                dispatch({
                    type: PORCENTAJE_ONBOARDING,
                    porcentajeOnboarding: 50
                })
                if (documentacionPorCuenta != null && documentacionPorCuenta.length > 0) {
                    var documentaciones = []
                    for (let elemento of documentacionPorCuenta) {
                        var documentacion = {
                            "new_documentoid": elemento.new_documentacionid
                        }
                        documentaciones.push(documentacion)
                    }
                    if (documentaciones.length > 0) {
                        await axios.post(`${UrlApi}api/onboarding/documentoporcuenta?socio_id=${accountid}
                        &solicitud_id=${solicitud_id}`,
                            documentaciones,
                            {
                                headers: {
                                    'Authorization': `Bearer ${token}`
                                }
                            })
                            .then(respuesta => {
                                if (respuesta.data == "ERROR") {
                                }
                            })
                            .catch(error => {
                                crearExcepcion(`Error al crear documentacion por cuenta para la cuenta 
                                    ${solicitud.nombreRazonSocial} cuit: ${solicitud.cuitCuilCdi} Correo: ${solicitud.email} - ${error}`, token)
                            })
                    }
                }

                dispatch({
                    type: PORCENTAJE_ONBOARDING,
                    porcentajeOnboarding: 65
                })

                if (files != null && files.length > 0) {
                    for (let index = 0; index < files.length; index++) {
                        var reFormData = new FormData();
                        var element = files[index];
                        reFormData.append(`body${index}/${files[index].documentoId}`, element);
                        await axios.post(`${UrlApi}api/onboarding/adjuntosdocuxcuenta?socio_id=${accountid}
                                        &solicitud_id=${solicitud_id}`, reFormData, {
                            headers: {
                                'Content-Type': 'multipart/form-data', // Importante para el envío de archivos
                                'Authorization': `Bearer ${token}`,
                            },
                        })
                            .then(respuesta => {
                                if (respuesta.data != "EXITO") {
                                    crearExcepcion(`Error al crear documento con adjunto para la cuenta ${accountid}`, token)
                                }
                            })
                            .catch(error => {
                                documentosNoCargados.push(element?.name)
                                let errorMessage = ''
                                if (error.response) {
                                    errorMessage = error.response.data.error;
                                }
                                else if (error.request) {
                                    // La solicitud se hizo pero no se recibió respuesta del servidor (puede deberse a problemas de red)
                                    errorMessage = 'Error en la solicitud: no se recibió respuesta del servidor'
                                } else {
                                    // Ocurrió un error durante la configuración de la solicitud
                                    errorMessage = `Error en la configuración de la solicitud: ${error.message}`
                                }
                                crearExcepcion(`Error al crear documento con adjunto para la cuenta ${accountid} - ${errorMessage}`, token)
                            })
                    }
                }

                dispatch({
                    type: PORCENTAJE_ONBOARDING,
                    porcentajeOnboarding: 85
                })

                if (accountid != null && solicitud.accionistas.length > 0) {
                    await axios.post(`${UrlApi}api/onboarding/accionistas?cuentaid=${accountid}`,
                        solicitud.accionistas,
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        })
                        .then(respuesta => {
                            resolve(respuesta.data)
                            if (respuesta.data == "ERROR") {
                                crearExcepcion(`Error al crear accionistas para la cuenta ${accountid} Correo: ${solicitud.email}`, token)
                                // estadoOnboarding = "ERROR POST CUENTA"
                            }
                        })
                        .catch(error => {
                            crearExcepcion(`Error al crear accionistas para la cuenta ${accountid} Correo: ${solicitud.email} - ${error}`, token)
                            // estadoOnboarding = "ERROR POST CUENTA"
                        })
                }
            } else {
                reject("Error al crear la cuenta.")
            }

            if (accountid != null && accountid != undefined) {
                //limpio el localstorage para poder realizar otro onboarding
                localStorage.clear()
                dispatch({
                    type: ONBOARDING_EXITO,
                    estadoOnboarding: 'EXITO',
                    loadingOnboarding: false,
                    porcentajeOnboarding: 100,
                    documentosNoCargados: documentosNoCargados,
                    razonSocial: solicitud.nombreRazonSocial
                })
            }

            resolve()

        })

        const response = await toast.promise(
            onboarding,
            {
                pending: 'Procesando solicitud...',
                success: 'Solicitud enviada 👌',
                error: {
                    render({ data }) {
                        dispatch({
                            type: ONBOARDING_ERROR,
                            estadoOnboarding: 'ERROR',
                            loadingOnboarding: false,
                            porcentajeOnboarding: 0,
                            // documentosNoCargados: []
                        })
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )
    }
    catch (error) {
        crearExcepcion(`Excepcion en solicitud de alta - ${solicitud.razonSocial} - ${solicitud.cuit} - ${solicitud.email} `, token)
        // dispatch({
        //     type: CUENTA_EXISTENTE,
        //     resultado: 'ERROR'
        // })
    }
}

const validarUsuarioEnDynamics = (solicitud, token) => new Promise(async (resolve, reject) => {
    var entidad = "accounts"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='account'>" +
        "<attribute name='accountid'/>" +
        "<attribute name='new_invitacion'/> " +
        "<filter type='and'>" +
        "<filter type='or'>" +
        "<condition attribute='emailaddress1' operator='eq' value='" + solicitud.email + "' />" +
        "<condition attribute='name' operator='eq' value='" + solicitud.razonSocial + "' />" +
        "<condition attribute='new_nmerodedocumento' operator='eq' value='" + solicitud.cuitCuilCdi + "' />" +
        "</filter>" +
        "</filter>" +
        "</entity>" +
        "</fetch>";

    axios.post(`${UrlApi}api/consultafetch`,
        {
            "entidad": entidad,
            "fetch": fetch
        },
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    )
        .then((data) => { return resolve(data) })
        .catch((error) => { return reject(error) })
})

const ObtenerAccountid = (solicitud_id, token) => new Promise(async (resolve, reject) => {
    var entidad = "accounts"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='account'>" +
        "<attribute name='accountid'/>" +
        "<filter type='and'>" +
        "<filter type='or'>" +
        "<condition attribute='new_solicituddealta' operator='eq' value='" + solicitud_id + "' />" +
        "</filter>" +
        "</filter>" +
        "</entity>" +
        "</fetch>";

    axios.post(`${UrlApi}api/consultafetch`,
        {
            "entidad": entidad,
            "fetch": fetch
        },
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    )
        .then((data) => { return resolve(data) })
        .catch((error) => { return reject(error) })
})

const crearSolicitud = (solicitud, invitacion, token) => new Promise(async (resolve, reject) => {
    axios.post(`${UrlApi}api/onboardingdoscero/`,
        {
            "personeria": solicitud.personeria,
            "razonSocial": solicitud.nombreRazonSocial,
            "cuit": solicitud.cuitCuilCdi,
            "cuitcuil": solicitud.cuitCuil,
            "email": solicitud.usuarioPortal,
            "telefono": solicitud.telefono,
            "nombreContacto": solicitud.nombre,
            "apellido": solicitud.apellido,
            "tipoDocumento": solicitud.tipoDeDocumento.value,
            "productoServicio": solicitud.servicio,
            "actividadAFIP": solicitud.actividad.value,
            "monto": '',
            "tipoRelacion": solicitud.relacionCuenta,
            "tipoSocietario": solicitud.tipoSocietario,
            "condicionImpositiva": solicitud.condicionImp.value,
            "cantidadMujeres": solicitud.cantidadMujeresDecision,
            "empleadas": solicitud.cantidadEmujeres,
            "discapacitados": solicitud.cantidadPdiscapacidad,
            "otro": solicitud.otro,
            "sectorEconomico": "",
            "inicioActividad": "",
            "resena": "",
            "emailNotificaciones": solicitud.emailNotificaciones,
            "invitacion": invitacion.toString(),
            "cuitReferidor": solicitud.cuitReferidor ? solicitud.cuitReferidor.value : null,
            "facturacion": solicitud.facturacionIngreso,
        },
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    )
        .then((data) => { return resolve(data) })
        .catch((error) => { return reject(error) })
})

export const setearRobot = (estado) => (dispatch) => {
    dispatch({
        type: ROBOT,
        robot: estado
    })
}
// const crearDocumentos = (accountid, solicitud_id, files, documentacionPorCuenta, invitacion, token) => new Promise(async (resolve, reject) => {
//     const configDocumentos = {
//         headers: {
//             'Authorization': `Bearer ${token}`,
//             'content-type': 'multipart/form-data',
//         },
//     };
//     if (files != null && files.length > 0) {
//         debugger
//         for (let arch of files) {
//             var formData = new FormData();
//             formData.append(`body`, arch);
//             var documentoid = arch.documentoId
//             axios.post(`https://hw365api.azurewebsites.net/api/onboarding/documentacionporcuenta?socio_id=${accountid}
//             &documento_id=${documentoid}&solicitud_id=${solicitud_id}&invitacion=${invitacion.toString()}`, formData, configDocumentos)
//                 .then(respuesta => {
//                     if (respuesta.data == "ERROR") {
//                         // estadoOnboarding = "ERROR POST CUENTA"
//                         crearExcepcion(`Error al crear documento con adjunto para la cuenta ${accountid}`, token)
//                     }
//                 })
//                 .catch(error => {
//                     crearExcepcion(`Error al crear documento con adjunto para la cuenta ${accountid} - ${error}`, token)
//                     // estadoOnboarding = "ERROR POST CUENTA"
//                 })
//         }
//         if (documentacionPorCuenta != null && documentacionPorCuenta.length > 0) {
//             var formData = new FormData();
//             debugger
//             for (let elemento of documentacionPorCuenta) {
//                 var docid = elemento.new_documentacionid
//                 axios.post(`https://hw365api.azurewebsites.net/api/onboarding/documentacionporcuenta?socio_id=${accountid}
//                                                 &documento_id=${docid}&solicitud_id=${solicitud_id}&invitacion=false`, formData, configDocumentos)
//                     .then(respuesta => {
//                         if (respuesta.data == "ERROR") {
//                             // estadoOnboarding = "ERROR POST CUENTA"
//                         }
//                     })
//                     .catch(error => {
//                         crearExcepcion(`Error al crear documento sin adjunto para la cuenta ${accountid} - ${error}`, token)
//                         // estadoOnboarding = "ERROR POST CUENTA"
//                     })
//             }
//         }
//     }
// })
// const crearAccionistas = (solicitud, accountid, token) => new Promise(async (resolve, reject) => {
//     axios.post(`https://hw365api.azurewebsites.net/api/onboarding/accionistas?cuentaid=${accountid}`,
//         solicitud.accionistas,
//         {
//             headers: {
//                 'Authorization': `Bearer ${token}`
//             }
//         })
//         .then(respuesta => {
//             resolve(respuesta.data)
//             if (respuesta.data == "ERROR") {
//                 // estadoOnboarding = "ERROR POST CUENTA"
//             }
//         })
//         .catch(error => {
//             reject(error)
//             // crearExcepcion(`Error al crear accionistas para la cuenta ${accountid} - ${error}`, token)
//             // estadoOnboarding = "ERROR POST CUENTA"
//         })
// })

const crearExcepcion = (error, token) => {
    axios.post(`${UrlApi}api/excepcion`,
        {
            "descripcion": error
        },
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
}
