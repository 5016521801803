import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

var firebaseConfig = {
  apiKey: "AIzaSyA9pR3SBTZ5MGF3kZCTVfZyfoP-g8QUvCA",
  authDomain: "portal-millssgr.firebaseapp.com",
  projectId: "portal-millssgr",
  storageBucket: "portal-millssgr.appspot.com",
  messagingSenderId: "91519510326",
  appId: "1:91519510326:web:cdb937f11b9222b70bf597"
};

app.initializeApp(firebaseConfig);

const firebase = app.firestore()
const auth = app.auth()
const storage = app.storage()

export {firebase, auth, storage}