const Entidad = "OneClickSgr";
const GoogleMapKey = "AIzaSyDwKzicjMvfVsf9SbEAZn9N7i4CxX6YO1I";
const RecaptchaKey = "6Lev96okAAAAAN2zO6wXV1hwl8WYnrnJgHYXzbtk";
const UrlApiDynamics = "https://sgroc365api.azurewebsites.net/api/";
const UnidadDeNegocio = "F7ACBC6B-9B47-E911-A9A4-000D3AC1B95D"
const UrlApi = "https://hw365api.azurewebsites.net/"
//Prod
const authMail = "admin@millssgr.com"
const authPass = "Mills.2022$"
//Parametros
const onboardingHabilitado = false
const readOnlyDatos = true
//Sandbox
// const authMail = "admin@millssgrsandbox.com"
// const authPass = "Mills.2022$"
 
//Localhost
// const UrlApiDynamics = "https://localhost:44348/api/";
// const RecaptchaKey = "6LeALiAcAAAAABv5WAeRHUmaf5GfqNSBYn8wTKON";
export {Entidad, GoogleMapKey, RecaptchaKey, UrlApiDynamics, UnidadDeNegocio, UrlApi, authMail, authPass, onboardingHabilitado, readOnlyDatos}