import { onboardingHabilitado } from './Keys'
import React from 'react'
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import SolicitudAlta from './Components/SolicitudAlta';
import Box from '@mui/material/Box';
import { auth } from './Firebase'
import { leerUsuarioActivo, actualizarAccountid, activarUsuarioFirebase } from './Redux/usuarios'
import { useDispatch, useSelector } from 'react-redux'
import Home from './Components/Home'
import { grey, teal, green } from '@mui/material/colors';
import Login from './Components/Login';
import ResumenPosicion from './Components/ResumenPosicion';
import Layout from './layout';
import CarpetaDigital from './Components/CarpetaDigital';
import Lineas from './Components/Lineas';
import Garantias from './Components/Garantias';
import Operaciones from './Components/Operaciones';
import Cuenta from './Components/Cuenta';
import Registrar from './Components/Registrar';
import { loginToken } from './Redux/Token'
import Recupero from './Components/Recupero';
import { MyProSidebarProvider } from './Components/global/sidebar/sidebarContext';
import AppBar from "./Components/Appbar"
import { obtenerCuenta } from './Redux/Cuenta'
import { Navigate } from 'react-router-dom';
import PrivateRouter from './Components/PrivateRouter'
import { obtenerLineasXUN } from './Redux/UnidadDeNegocio'

function App() {
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  const [dark, setDark] = React.useState(true)
  const [loggedUser, setLoggedUser] = React.useState(null)
  const [loadingAPP, setLoadingAPP] = React.useState(false)
  const [cuenta, setCuenta] = React.useState(null)
  const [llamadaDatosCuenta, setLlamadaDatosCuenta] = React.useState(false)
  const [login, setLogin] = React.useState(false)
  const [mostrarLineas, setMostrarLineas] = React.useState(false)
  const [lineaCargada, setLineaCargada] = React.useState(false)

  const accountid = useSelector(store => store.usuarios.accountid)
  const cuentaSelector = useSelector(store => store.cuenta.cuenta)
  const mostrarLineasSelector = useSelector(store => store.unidadesNegocio.mostrarLineasPortal)
  //foto del perfil
  const [fotoURL, setFotoURL] = React.useState()
  const fotoselector = useSelector(store => store.usuarios.photoURL)
  const activoSelector = useSelector(store => store.usuarios.activo)
  const token = useSelector(store => store.token.token)

  React.useEffect(() => {
    if (token != undefined && token != '') {
      dispatch(obtenerLineasXUN(token.token))
    }
  }, [token])

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(loginToken(token));
    }, 900000);
    return () => clearInterval(intervalId);
  }, [])

  React.useEffect(() => {
    let parametros = JSON.parse(localStorage.getItem('parametros'))
    if (parametros != null && parametros.mostrarLineas != null) {
      setMostrarLineas(parametros.mostrarLineas)
      setLineaCargada(true)
    }
    if (mostrarLineasSelector?.length > 0 && lineaCargada === false) {
      let lineas = mostrarLineasSelector[0]
      setMostrarLineas(lineas.new_mostrarlineascomercialesenportalsocio)
      setLineaCargada(true)
      localStorage.setItem('parametros', JSON.stringify({
        mostrarLineas: lineas.new_mostrarlineascomercialesenportalsocio
      }))
    } else if (mostrarLineasSelector?.length > 0 && lineaCargada === true && parametros != null) {
      let lineas = mostrarLineasSelector[0]
      if (lineas.new_mostrarlineascomercialesenportalsocio !== parametros.mostrarLineas) {
        setMostrarLineas(lineas.new_mostrarlineascomercialesenportalsocio)
        parametros.mostrarLineas = lineas.new_mostrarlineascomercialesenportalsocio
        localStorage.setItem('parametros', JSON.stringify({
          parametros
        }))
      }
    }
  }, [mostrarLineasSelector])

  React.useEffect(() => {
    var modo = JSON.parse(localStorage.getItem("Dark Mode"))
    if (modo != null) {
      if (modo == true) {
        setDark(true)
      } else {
        setDark(false)
      }
    }
  }, [])

  React.useEffect(() => {
    if (activoSelector) {
      setLoggedUser(true)
    } else if (!activoSelector) {
      setLoggedUser(false)
    }
  }, [activoSelector])

  React.useEffect(() => {
    const fetchUser = () => {
      auth.onAuthStateChanged(user => {
        if (user) {
          if (localStorage.getItem('usuario')) {
            setTimeout(() => {
              setLoadingAPP(true)
            }, 100)
            dispatch(leerUsuarioActivo())
          } else {
            setTimeout(() => {
              setLoadingAPP(true)
            }, 1000)
            dispatch(actualizarAccountid(user.uid))
          }
          if (token == undefined || token == "") {
            dispatch(loginToken())
          }
        } else {
          clearCacheData()
          setLoggedUser(false)
        }
      })
    }
    fetchUser()
  }, []);

  React.useEffect(() => {
    if (accountid !== '') {
      if (cuentaSelector != undefined && Object.entries(cuentaSelector).length > 0) {
        if (cuentaSelector.accountid === accountid) {
          setCuenta(cuentaSelector)
          validarUsuarioActivo(cuentaSelector)
        } else {
          obtenerInfoMiCuenta()
        }
      } else if (token != undefined && token != '' && llamadaDatosCuenta == false) {
        obtenerInfoMiCuenta()
        setLlamadaDatosCuenta(true)
      } else if (cuentaSelector != undefined && Object.entries(cuentaSelector).length > 0 &&
        llamadaDatosCuenta == true) {
        if (cuentaSelector.accountid !== accountid) {
          obtenerInfoMiCuenta()
        }
      } else if (Object.entries(cuentaSelector).length === 0 &&
        llamadaDatosCuenta == true) {
        setCuenta(null)
        obtenerInfoMiCuenta()
      }
    }
  }, [cuentaSelector, accountid, token])

  const validarUsuarioActivo = (usuario) => {
    let usuarioStorage = JSON.parse(localStorage.getItem('usuario'))
    if (usuarioStorage != null && usuarioStorage.Estado !== usuario.new_estadodelsocio && usuario.new_estadodelsocio === 100000000) {
      dispatch(activarUsuarioFirebase(usuarioStorage.uid, usuario.new_estadodelsocio))
    }
  }

  React.useEffect(() => {
    if (fotoselector) {
      setFotoURL(fotoselector)
    }
  }, [fotoselector, fotoURL])

  const obtenerInfoMiCuenta = () => {
    dispatch(obtenerCuenta(accountid, token.token))
  }

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  //este es el theme que cambia los modos de color
  const temaOscuroBlanco = createTheme({
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      fontFamily: [
        'Roboto',
        'Nunito',
        'Helvetica Neue',
        'Arial',
        'sans-serif'
      ].join(','),
      fontSize: 12,
      primary: {
        ...(dark === true && {
          color: green[800],
          main: 'rgb(39, 157, 133)',
          text: 'rgba(255, 255, 255, 0.87)'
        }),
        ...(dark === false && {
          color: 'rgb(39, 157, 133)',
          // color: teal[800],
          text: 'rgba(0, 0, 0, 0.87)'
        }),
      }
    },
    palette: {
      mode: dark ? 'dark' : 'light',
      background: {
        ...(dark === true && {
          default: 'rgb(26, 32, 53)',
          paper: 'rgb(26, 32, 53)',
          main: "rgb(26, 32, 53)",
          light: 'rgb(26, 32, 53, 0.7)',
          list: 'rgb(41, 49, 79)',
        }),
        ...(dark === false && {
          default: '#fff',
          paper: '#f2f5f9',
          main: '#f2f5f9',
          light: 'rgb(243, 243, 243, 0.7)', 
          list: '#f2f5f9',
        }),
      },
      primary: {
        ...teal,
        fontSize: 20,
        ...(dark === true && {
          main: '#43a047',
          bad: '#43a047',
          pend: '#43a047'
          // main: '#121212'
        }),
        ...(dark === false && {
          main: '#43a047',
          bad: '#43a047',
          pend: '#43a047'
          // main: '#121212'
        }),
      },
      secondary: {
        ...(dark === true && {
          // main: green[800],
          main: '#f5f5f5'
        }),
        ...(dark === false && {
          // main: teal[100],
          main: grey[800],

        }),
      },
    },
  })

  const setearColor = () => {
    setDark(!dark)
    let tema = JSON.parse(localStorage.getItem("Dark Mode"))
    if (tema != null) {
      tema = !dark
      localStorage.setItem("Dark Mode", JSON.stringify(tema))
    } else {
      localStorage.setItem("Dark Mode", JSON.stringify(!dark))
    }
  }

  return (
    <ThemeProvider theme={temaOscuroBlanco}>
      <CssBaseline />
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <BrowserRouter basename="">
          <MyProSidebarProvider loggedUser={loggedUser} loadingAPP={false} mostrarLineas={mostrarLineas}>
            <div style={{ height: "100%", width: "100%" }}>
              <main>
                {
                  loggedUser ?
                    <AppBar
                      usuario={cuenta || {}}
                      foto={fotoURL || ''}
                      setearColor={setearColor}
                      loggedUser={loggedUser}
                    />
                    : null
                }
                <Routes>
                  <Route path='/' exact element={
                    <PrivateRouter loggedUser={loggedUser}>
                      <ResumenPosicion cuenta={cuenta} mostrarLineas={mostrarLineas} />
                    </PrivateRouter>} />
                  <Route path='/midocumentaciondigital' element={
                    <PrivateRouter loggedUser={loggedUser}>
                      <CarpetaDigital />
                    </PrivateRouter>} />
                  <Route path='/micuenta'
                    element={
                      <PrivateRouter loggedUser={loggedUser}>
                        <Cuenta />
                      </PrivateRouter>} />
                  <Route path='/misgarantias'
                    element={
                      <PrivateRouter loggedUser={loggedUser} >
                        <Garantias />
                      </PrivateRouter>} />
                  <Route path='/mislineas'
                    element={
                      <PrivateRouter loggedUser={loggedUser} >
                        <Lineas />
                      </PrivateRouter>} />
                  <Route path='/misoperaciones'
                    element={
                      <PrivateRouter loggedUser={loggedUser} >
                        <Operaciones />
                      </PrivateRouter>} />
                  {
                    onboardingHabilitado ?
                      <Route path='/solicitud-alta' element={<SolicitudAlta setearColor={setearColor} />} >
                        <Route index element={<Home />} />
                      </Route> : null
                  }
                  <Route path='/login'>
                    <Route index element={<Login />} />
                  </Route>
                  <Route path='/Registro'>
                    <Route index element={<Registrar />} />
                  </Route>
                  <Route path='/Recupero'>
                    <Route index element={<Recupero />} />
                  </Route>
                  <Route path='*'>
                    <Route element={<Navigate to="/login" replace />} />
                  </Route>
                </Routes>
              </main>
            </div>
          </MyProSidebarProvider>
        </BrowserRouter>
      </Box>
    </ThemeProvider >
  );
}

export default App;
